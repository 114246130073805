import React from 'react';

const Home = () => {
    return (
        <div>
            <p>Welcome to CRYPTOVERSE!</p>
        </div>
    );
};

export default React.memo(Home);