import React, { useCallback, useEffect, useState, useContext } from 'react';
import websocket from '../../libs/websocket';
import Websocket from '../../libs/websocket-new';
import {createCurrencyPriceSocketUrl} from '../../libs/utility';
import { usePrevious } from '../../hooks/usePrevious';
import './style.scss';
import { StarFilled} from '@ant-design/icons';
import { Context } from '../../store';
import {Row, Col} from 'antd';
import TechnicalAnalysisWidget from '../TechnicalAnalysisWidget';

const CurrencyInfo = ({marketType, currency}) => {
    const {state} = useContext(Context);

    const symbolName = marketType === "FUTURES" ? currency.baseAsset?.toLowerCase() : 'binance';
    const [currentPrice, setCurrentPrice] = useState(0);
    const prevPrice = usePrevious(currentPrice);

    const listenMessage = useCallback((message) => {
        if(message.p) { 
          setCurrentPrice(Number(message.p).toFixed(4));
        } 
    }, []);
    
     useEffect(() => {
        //console.log('CurrencyInfo USEEFFECT');
        //console.log('START LISTEN PRICE SOCKET');
        const coninSocket = new Websocket();
        
        if(Object.keys(currency).length > 0) {
            let socketUrl = createCurrencyPriceSocketUrl(marketType, currency.symbol);

            coninSocket.open(socketUrl, listenMessage);
        }

        return () => {
            if(Object.keys(currency).length > 0) {
                if(coninSocket.isSocketInitialized()) {
                    coninSocket.close();
                }
            }
        } 
         
    }, [listenMessage, currency, marketType]); 
 
    return (
        <div className="currency-info">
            <Row gutter="16">
                <Col span="12">
                    <div className="currency">
                        <div className='currency-image'>
                            <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${symbolName}.svg`} alt="" />
                        </div>
                        <div className='currency-title'>
                            <p>{currency.symbol}</p>
                            <span>( {currency.name} )</span>
                        </div>
                    </div>
                </Col>
                <Col span="12">
                    <div className='currency-links'>
                        <ul>
                            <li> 
                                <a href={currency.official} title="" target="_blank" rel="noopener noreferrer">Official Site</a>
                            </li>
                            <li>
                                <a href={`https://www.binance.com/en/futures/${currency.symbol}`} title="" target="_blank" rel="noopener noreferrer">Binance</a>
                            </li>
                            <li>
                                <a href={`https://tr.tradingview.com/chart/r1l5KEUG/?symbol=${currency.symbol}PERP`} title="" target="_blank" rel="noopener noreferrer">Trading View</a>
                            </li>
                            <li>
                                <a href={`https://coinmarketcap.com/tr/currencies/${currency.name?.toLowerCase()}`} title="" target="_blank" rel="noopener noreferrer">Coin Market</a>
                            </li>
                            <li>
                                <a href={`https://tr.investing.com/crypto/${currency.name?.toLowerCase()}`} title="" target="_blank" rel="noopener noreferrer">Investing</a>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col span="24">
                    <div className='currency-price'>
                        <p className={currentPrice > prevPrice ? "green" : "red"}>{currentPrice} $</p>
                    </div>
                </Col>
                <Col span="12">
                    <TechnicalAnalysisWidget currency={currency} marketType={marketType} />
                </Col>
            </Row>
        </div>
    );
};

export default CurrencyInfo;