import {analysisTypes, fibonacci} from './enums';
import { std } from 'mathjs';
import {getPassingTime} from './utility';
let beforeEMAs = {
    ema5: null,
    ema8: null,
    ema13: null,
    ema21: null,
    ema34: null,
    ema50: null,
    ema55: null,
    ema89: null,
    ema100: null,
    ema144: null,
    ema233: null,
    ema377: null,
    highEma8: null,
    highEma13: null,
    lowEma8: null,
    lowEma13: null
}

let prevAvg = {}
let prevObv = 0;

let rsiValue = 14;
let spotPrevKlines = 30;
let gunstockPrevKlines = 10;
let pivotValue = 6;
let rsiDivergenceType = "loose";
let underflow = false;
let underflowCount = 0;
let overflow = false;
let overflowCount = 0;

// 4 Saatlik Supply- Demand bölgelerini belirler.
export const allCurrencySupDemAnalysis = async (symbols, incomingKlines, getThemAll) => {
    if (getThemAll) {
            symbols = incomingKlines;
    }

    Object.keys(symbols).map((symbol, i) => {

        symbols[symbol].supdem = symbols[symbol].supdem || [];

        if(!getThemAll) {
            symbols[symbol].candles.push(...incomingKlines[symbol].candles);
        }

        //console.log('allCurrencySupDemAnalysis', symbol, symbols[symbol], incomingKlines[symbol]);

        let twoPrevKline = [];
        let prevKlines = [];

        // indicator bölümü setleniyor.
        symbols[symbol].candles.map((kline, ix) => {

            twoPrevKline = symbols[symbol].candles[ix - 2];
            prevKlines = [...symbols[symbol].candles].splice(ix - 5, 5); 

            // FIND SUPPLY DEMAND AREAS
            if (ix > 5) {
                let supDemArea = findSupplyDemandAreas(kline, prevKlines, symbols[symbol].supdem);
                //console.log('supDemArea', supDemArea);

                if (supDemArea.invalidSupDemAreas.length > 0) {
                    supDemArea.invalidSupDemAreas.map(sd => {
                        //console.log('Invalid Array', symbol, kline.candlestick.localOpenTime, sd);
                        let index = symbols[symbol].supdem.findIndex(sin => sin.openTime === sd.openTime);
                        symbols[symbol].supdem[index].state = 'inactive';
                        symbols[symbol].supdem[index].expiredDate = kline.candlestick.closeTime;
                    });
                }

                if (supDemArea.supdemArea) {
                    symbols[symbol].supdem.push(supDemArea.supdemArea);
                } 
            }

            return kline;
        });

        symbols[symbol].candles = [...symbols[symbol].candles].splice(symbols[symbol].candles.length - 5, 5); 

        return true;
    });

    //console.log('last update için', symbols[Object.keys(symbols)[0]].candles);

    return {symbols: symbols, lastUpdate: symbols[Object.keys(symbols)[0]].candles[symbols[Object.keys(symbols)[0]].candles.length - 1].candlestick.openTime};

}

export const allCurrencyCandlestickAnalysis = async (symbols, symbolList, analysisModel, simulator = false, analysisOptions, supdemData) => {
    //const newCandlesticksObj = {}
    const supportResistance = {};
    const isPivotSourceClose = analysisOptions.pivotSource === 'close';

    const allPastPositionsList = [];
    const allOpenPositionsList = [];
    const activeDeals = [];
    
    Object.keys(symbols).map((symbol, i) => {

        symbols[symbol].supdem = [];

        let prevKline = [];
        let twoPrevKline = [];
        let prevKlines = [];
        let beforeComparisonResult = '';
    
        let currentDeal = {
            key: symbol,
            status: '',
            symbol: symbol,
            openPrice: null,
            highPrice: null,
            closePrice: null,
            openDate: null,
            closeDate:  null,
            posType: '',
            closeType: null
        };

        // indicator bölümü setleniyor.
        symbols[symbol].candles.map((kline, ix) => {

            twoPrevKline = symbols[symbol].candles[ix - 2];
            prevKlines = [...symbols[symbol].candles].splice(ix - 5, 5); 

            if(ix > 5) {
                if(!simulator) {
                    let comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal, analysisOptions, supdemData.symbols[symbol]?.supdem || []);

                    if(!analysisOptions.noClosing) {

                        if(currentDeal.status === "LONG" || currentDeal.status === "SHORT") {
                            currentDeal.candleCount++;
    
                            if(currentDeal.openPrice) {
                                const closeMinusPerc = (Number(kline.candlestick.close) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                                let tempCloseReverse = currentDeal.closeReverse;
                                currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;
                                let tempMaxReverse = currentDeal.maxReverse;
                                
                                let pnl = Number(((kline.candlestick.close - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
    
                                if(currentDeal.status === "LONG") {
                                    currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                                    const maxMinusPerc = (Number(kline.candlestick.low) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                                    currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                                    currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;
        
                                    currentDeal.highPriceIndex = Number(kline.candlestick.close) > currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                                    currentDeal.highPrice = Number(kline.candlestick.close) > currentDeal.highPrice ? Number(kline.candlestick.close) : currentDeal.highPrice;
                                    currentDeal.topNeedleIndex = Number(kline.candlestick.high) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                                    currentDeal.topNeedle = Number(kline.candlestick.high) > currentDeal.topNeedle ? Number(kline.candlestick.high) : currentDeal.topNeedle;    
                                    
                                    if(currentDeal.candleCount === 1) {
                                        currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                                    } 
    
                                    currentDeal.highProfit = Number(((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                                    currentDeal.mostProfit = Number(((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                                }
                                else if(currentDeal.status === "SHORT") {
                                    currentDeal.closeReverse = -1 * closeMinusPerc < tempCloseReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
                                    const maxMinusPerc = (Number(kline.candlestick.high) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                                    currentDeal.maxReverse = -1 * maxMinusPerc < tempMaxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;
                                    currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;
        
                                    currentDeal.highPriceIndex = Number(kline.candlestick.close) < currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                                    currentDeal.highPrice = Number(kline.candlestick.close) < currentDeal.highPrice ? Number(kline.candlestick.close) : currentDeal.highPrice;
                                    currentDeal.topNeedleIndex = Number(kline.candlestick.low) < currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                                    currentDeal.topNeedle = Number(kline.candlestick.low) < currentDeal.topNeedle ? Number(kline.candlestick.low) : currentDeal.topNeedle;    
        
                                    if(currentDeal.candleCount === 1) {
                                        currentDeal.firstReverse = -1 * maxMinusPerc < tempMaxReverse ? Number(-1 * maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                                    }
    
                                    currentDeal.highProfit = Number(-1 * ((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                                    currentDeal.mostProfit = Number(-1 * ((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));    
                                    
                                    pnl = -1 * pnl; 
                                }
    
                                currentDeal.closePrice = Number(kline.candlestick.close);
                                currentDeal.closeDate = kline.candlestick.closeTime;
                                currentDeal.time = getPassingTime(new Date(kline.candlestick.closeTime), new Date(currentDeal.openDate));
                                
                                if(comparisonResult.closeType === "CP-RC") {
                                    pnl = pnl;
                                }
                                else if(comparisonResult.closeType === "CP-TP") {
                                    pnl = analysisOptions.takeProfit;
                                }
                                else if(comparisonResult.closeType === "CP-ES") {
                                    pnl = 0;
                                }
                                else if(comparisonResult.closeType === "CP-TS") {
                                    pnl = currentDeal.mostProfit - analysisOptions.trailingCallback;
                                }
                                else {
                                    pnl = currentDeal.maxReverse <= -analysisOptions.stopLoss ? -analysisOptions.stopLoss : pnl;
                                }
     
                                currentDeal.pnl = Number(pnl.toFixed(2)); 
                                currentDeal.realPnL = Number(((analysisOptions.entryAmount / 100) * pnl).toFixed(2));
                            }
    
                        }
    
                        if(currentDeal.status !== comparisonResult.position) {     
                    
                            if(currentDeal.status !== '' && comparisonResult.position === "CLOSE") {  
                                currentDeal.closeType = comparisonResult.closeType;
    
                                // all position object push
                                allPastPositionsList.push({...currentDeal});
                                currentDeal.status = "CLOSE";
                                currentDeal.openPrice = null;
                                currentDeal.highPrice = null;
                                currentDeal.highPriceIndex = 0;
                                currentDeal.topNeedle = null;
                                currentDeal.topNeedleIndex = 0;
                                currentDeal.posType = "";
                                currentDeal.closeReverse = 0;
                                currentDeal.closeReverseIndex = 0;
                                currentDeal.maxReverse = 0;
                                currentDeal.maxReverseIndex = 0;
                                currentDeal.firstReverse = 0;
                                currentDeal.candleCount = 0;   
                                
                                // Eğer poz kapanırsa aynı mumda LONG ya da SHORT yeniden açılabilir mi kontrolü.
                                beforeComparisonResult = currentDeal.status;
                                comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal, analysisOptions, supdemData.symbols[symbol]?.supdem || []);
                            }
            
                            if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                                let tempOpenPrice = Number(kline.candlestick.close);//0;
                                /*if(comparisonResult === "LONG") {
                                    tempOpenPrice = kline.candlestick.color === "green" ? kline.candlestick.low + ((kline.candlestick.open - kline.candlestick.low) / 2) : kline.candlestick.low + ((kline.candlestick.close - kline.candlestick.low) / 2);
                                }
                                else {
                                    tempOpenPrice = kline.candlestick.color === "green" ? kline.candlestick.high - ((kline.candlestick.high - kline.candlestick.close) / 2) : kline.candlestick.high - ((kline.candlestick.high - kline.candlestick.open) / 2);
                                }*/

                                currentDeal.key = ix + symbol;
                                currentDeal.closeType = null;
                                currentDeal.status = comparisonResult.position;
                                currentDeal.posType = comparisonResult.posType;
                                currentDeal.openPrice = tempOpenPrice; //Number(kline.candlestick.close);
                                currentDeal.highPrice = Number(kline.candlestick.close);
                                currentDeal.topNeedle = Number(kline.candlestick.close);
                                currentDeal.closePrice = Number(kline.candlestick.close);
                                currentDeal.openDate = kline.candlestick.closeTime;
                                currentDeal.closeDate = kline.candlestick.closeTime;
                                currentDeal.time = 0;
                                currentDeal.highProfit = 0;
                                currentDeal.mostProfit = 0;
                                currentDeal.candleCount = 0;
                                currentDeal.closeReverse = 0;
                                currentDeal.closeReverseIndex = 0;
                                currentDeal.maxReverse = 0;
                                currentDeal.maxReverseIndex = 0;
                                currentDeal.firstReverse = 0;
                                currentDeal.pnl = 0;
                                currentDeal.realPnL = 0;
                                currentDeal.point = comparisonResult.point;
    
                                if(analysisModel === "depthHill") {
                                    currentDeal.supportLine = comparisonResult.supportLine;
                                }  
                            }
                        }
                    }
                    else {           
                        allPastPositionsList.filter(s => s.symbol === symbol && s.closeType === null).map(pos => {
                            pos.candleCount++;
                            const closeMinusPerc = (Number(kline.candlestick.close) - pos.openPrice) * 100 / pos.openPrice;
                            let tempCloseReverse = pos.closeReverse;
                            pos.closeReverseIndex = closeMinusPerc < tempCloseReverse ? pos.candleCount : pos.closeReverseIndex;
                            let tempMaxReverse = pos.maxReverse;
                            
                            let pnl = Number(((kline.candlestick.close - pos.openPrice) * 100 / pos.openPrice).toFixed(2));

                            if(pos.status === "LONG") {
                                pos.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : pos.closeReverse;
                                const maxMinusPerc = (Number(kline.candlestick.low) - pos.openPrice) * 100 / pos.openPrice;
                                pos.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : pos.maxReverse;
                                pos.maxReverseIndex = maxMinusPerc < tempMaxReverse ? pos.candleCount : pos.maxReverseIndex;
    
                                pos.highPriceIndex = Number(kline.candlestick.close) > pos.highPrice ? pos.candleCount : pos.highPriceIndex;
                                pos.highPrice = Number(kline.candlestick.close) > pos.highPrice ? Number(kline.candlestick.close) : pos.highPrice;
                                pos.topNeedleIndex = Number(kline.candlestick.high) > pos.topNeedle ? pos.candleCount : pos.topNeedleIndex;
                                pos.topNeedle = Number(kline.candlestick.high) > pos.topNeedle ? Number(kline.candlestick.high) : pos.topNeedle;    
                                
                                if(pos.candleCount === 1) {
                                    pos.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : pos.maxReverse;
                                } 

                                pos.highProfit = Number(((pos.highPrice - pos.openPrice) * 100 / pos.openPrice).toFixed(2));
                                pos.mostProfit = Number(((pos.topNeedle - pos.openPrice) * 100 / pos.openPrice).toFixed(2));           
                            }
                            else if(pos.status === "SHORT") {
                                pos.closeReverse = -1 * closeMinusPerc < tempCloseReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : pos.closeReverse;
                                const maxMinusPerc = (Number(kline.candlestick.high) - pos.openPrice) * 100 / pos.openPrice;
                                pos.maxReverse = -1 * maxMinusPerc < tempMaxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : pos.maxReverse;
                                pos.maxReverseIndex = maxMinusPerc < tempMaxReverse ? pos.candleCount : pos.maxReverseIndex;
    
                                pos.highPriceIndex = Number(kline.candlestick.close) < pos.highPrice ? pos.candleCount : pos.highPriceIndex;
                                pos.highPrice = Number(kline.candlestick.close) < pos.highPrice ? Number(kline.candlestick.close) : pos.highPrice;
                                pos.topNeedleIndex = Number(kline.candlestick.low) < pos.topNeedle ? pos.candleCount : pos.topNeedleIndex;
                                pos.topNeedle = Number(kline.candlestick.low) < pos.topNeedle ? Number(kline.candlestick.low) : pos.topNeedle;    
    
                                if(pos.candleCount === 1) {
                                    pos.firstReverse = -1 * maxMinusPerc < tempMaxReverse ? Number(-1 * maxMinusPerc.toFixed(2)) : pos.maxReverse;
                                }

                                pos.highProfit = Number(-1 * ((pos.highPrice - pos.openPrice) * 100 / pos.openPrice).toFixed(2));
                                pos.mostProfit = Number(-1 * ((pos.topNeedle - pos.openPrice) * 100 / pos.openPrice).toFixed(2));    
                                
                                pnl = -1 * pnl; 
                            }

                            pos.closePrice = Number(kline.candlestick.close);
                            pos.closeDate = kline.candlestick.closeTime;
                            pos.time = getPassingTime(new Date(kline.candlestick.closeTime), new Date(pos.openDate));

                            if(pos.maxReverse <= -2.5) {
                                pos.closeType = "CP-SL";
                                pnl = -2.5;
                            }
                            else if (pos.mostProfit >= 0.5/* && kline.candlestick.close * 1.01 < pos.topNeedle*/) {
                                pos.closeType = "CP-TP";
                                pnl = 0.5;//pos.mostProfit - 1;                             
                            }
                            pos.pnl = Number(pnl.toFixed(2));
                            pos.realPnL = Number(((analysisOptions.entryAmount / 100) * pnl).toFixed(2));
                        });
                        
                        if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                            currentDeal.key = ix + symbol;
                            currentDeal.closeType = null;
                            currentDeal.status = comparisonResult.position;
                            currentDeal.posType = comparisonResult.posType;
                            currentDeal.openPrice = Number(kline.candlestick.close);
                            currentDeal.highPrice = Number(kline.candlestick.close);
                            currentDeal.topNeedle = Number(kline.candlestick.close);
                            currentDeal.closePrice = null;
                            currentDeal.openDate = kline.candlestick.closeTime;
                            currentDeal.closeDate = null;
                            currentDeal.time = null;
                            currentDeal.highProfit = null;
                            currentDeal.mostProfit = null;
                            currentDeal.candleCount = 0;
                            currentDeal.closeReverse = 0;
                            currentDeal.closeReverseIndex = 0;
                            currentDeal.maxReverse = 0;
                            currentDeal.maxReverseIndex = 0;
                            currentDeal.firstReverse = 0;
                            currentDeal.pnl = null;
                            currentDeal.realPnL = null;
                            currentDeal.point = comparisonResult.point;  
                            
                            allPastPositionsList.push({...currentDeal});
                        }
                    }

                    // son muma geldiysek;
                    if(ix === symbols[symbol].candles.length - 1 && currentDeal.status !== '') {

                        // son mum 'close' degil yani hala açık bir pozisyon.
                        if(currentDeal.status !== "CLOSE") {
                            currentDeal.closeType = "NC";
                            allOpenPositionsList.push({...currentDeal}); 
                        }

                        // son mumda değişiklik oluyor yani current deal.
                        //console.log('last candlestick', beforeComparisonResult, currentDeal.status);
                        if(beforeComparisonResult !== currentDeal.status) {
                            let activeDeal = {
                                key: symbol,
                                status: '',
                                symbol: symbol,
                                price: kline.candlestick.close,
                                date: kline.candlestick.closeTime,
                                volume: kline.candlestick.vol,
                                posType: currentDeal.posType,
                                closeType: currentDeal.closeType,
                                ema20: null, // kline.indicators.ema20,
                                ema50: null, // kline.indicators.ema50,
                                ema100: null, // kline.indicators.ema100,
                                ema200: null, // kline.indicators.ema200,
                                prevRsi: null, //prevKline.indicators.rsi,
                                rsi: null, // kline.indicators.rsi,
                                bbUp: null, // kline.indicators.bollinger.upper,
                                bbMid: null, // kline.indicators.bollinger.middle,
                                bbLow: null, // kline.indicators.bollinger.lower,
                                strength: currentDeal.point
                            };
            
                            if (currentDeal.status === "CLOSE" || currentDeal.status === "LONGCLOSE") {
                                activeDeal.status = 'CLOSE POSITION';
                            }
                            else {
                                activeDeal.status = `OPEN ${currentDeal.status}`;
                            }
                            //console.log('Current Single Deal:', currentDeal);
                            activeDeals.push({...activeDeal}); 
                        }
                    }

                    beforeComparisonResult = !analysisOptions.noClosing ? comparisonResult.position : "";
                }
            }

            return kline;
        });

        return true;
    });

    return {candlesticks: symbols, pastPositions: allPastPositionsList, openPositions: allOpenPositionsList, supports: supportResistance, activeDeals: activeDeals };
}

export const allCurrencyLastCandlestickAnalysis = async (symbols, lastKlines, symbolList, analysisModel, openPositions, analysisOptions, supdemData) => {
    // güncellenen yeni mumların eski liste ile analiz edilip döndürülmesi.
    const supportResistance = {};
    let updatedPositions = [];
    const activeDeals = [];

    //console.log('allCurrencyLastCandlestickAnalysis', symbols, lastKlines);

    Object.keys(lastKlines).map((symbol, i) => {

        let symbolsLength = symbols[symbol].candles.length;
        let prevKline = symbols[symbol].candles[symbolsLength - 1];


        let lastKline = lastKlines[symbol].candles;
        prevKline = symbols[symbol].candles[symbolsLength - 2];
        let prevKlines = [];
        prevKlines = [...symbols[symbol].candles].splice(symbolsLength - 6, 5); 

        symbols[symbol].candles.push(lastKlines[symbol].candles);

        let openPosition = openPositions && openPositions.find(p => p.symbol === symbol);

        let beforeComparisonResult;
        let currentDeal = {};

        if(openPosition) {
            beforeComparisonResult = openPosition.status;
            currentDeal = {...openPosition};
        }
        else {
            beforeComparisonResult = 'CLOSE';
            currentDeal = {
                symbol: symbol,
            }
        }

        let comparisonResult = calculatePositions(lastKline, prevKlines, beforeComparisonResult, currentDeal, analysisOptions, supdemData.symbols[symbol]?.supdem || []);

        if(!analysisOptions.noClosing) { 
            if(beforeComparisonResult === "LONG" || beforeComparisonResult === "SHORT") {
                currentDeal.candleCount++;
    
                if(currentDeal.openPrice) {
                    const closeMinusPerc = (Number(lastKline.candlestick.close) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                    let tempCloseReverse = currentDeal.closeReverse;
                    currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;
                    let tempMaxReverse = currentDeal.maxReverse;
    
                    let pnl = Number(((lastKline.candlestick.close - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
    
                    if(beforeComparisonResult === "LONG") {
                        currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                        const maxMinusPerc = (Number(lastKline.candlestick.low) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;
        
                        currentDeal.highPriceIndex = Number(lastKline.candlestick.close) > currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                        currentDeal.highPrice = Number(lastKline.candlestick.close) > currentDeal.highPrice ? Number(lastKline.candlestick.close) : currentDeal.highPrice;
                        currentDeal.topNeedleIndex = Number(lastKline.candlestick.high) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                        currentDeal.topNeedle = Number(lastKline.candlestick.high) > currentDeal.topNeedle ? Number(lastKline.candlestick.high) : currentDeal.topNeedle;
                        if(currentDeal.candleCount === 1) {
                            currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        } 
    
                        currentDeal.highProfit = Number(((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                        currentDeal.mostProfit = Number(((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));                     
                    }
                    else if(beforeComparisonResult === "SHORT") {
                        currentDeal.closeReverse = -1 * closeMinusPerc < tempCloseReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
                        const maxMinusPerc = (Number(lastKline.candlestick.high) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        currentDeal.maxReverse = -1 * maxMinusPerc < currentDeal.maxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;     
                        currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;
    
                        currentDeal.highPriceIndex = Number(lastKline.candlestick.close) < currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                        currentDeal.highPrice = Number(lastKline.candlestick.close) < currentDeal.highPrice ? Number(lastKline.candlestick.close) : currentDeal.highPrice;
                        currentDeal.topNeedleIndex = Number(lastKline.candlestick.low) < currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                        currentDeal.topNeedle = Number(lastKline.candlestick.low) < currentDeal.topNeedle ? Number(lastKline.candlestick.low) : currentDeal.topNeedle;    
    
                        if(currentDeal.candleCount === 1) {
                            currentDeal.firstReverse = -1 * maxMinusPerc < tempMaxReverse ? Number(-1 * maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        }
    
                        currentDeal.highProfit = Number(-1 * ((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                        currentDeal.mostProfit = Number(-1 * ((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));    
                    
                        pnl = -1 * pnl; 
                    }
    
                    currentDeal.closePrice = Number(lastKline.candlestick.close);
                    currentDeal.closeDate = lastKline.candlestick.closeTime;
                    currentDeal.time = getPassingTime(new Date(lastKline.candlestick.closeTime), new Date(currentDeal.openDate));
                    //currentDeal.closeType = comparisonResult.closeType;
    
                    if(comparisonResult.closeType === "CP-TP") {
                        pnl = analysisOptions.takeProfit;
                    }
                    else if(comparisonResult.closeType === "CP-ES") {
                        pnl = 0;
                    }
                    else if(comparisonResult.closeType === "CP-TS") {
                        pnl = currentDeal.mostProfit - analysisOptions.trailingCallback;
                    }
                    else {
                        pnl = currentDeal.maxReverse <= -analysisOptions.stopLoss ? -analysisOptions.stopLoss : pnl;
                    }
    
                    currentDeal.pnl = Number(pnl.toFixed(2));
                    currentDeal.realPnL = Number(((analysisOptions.entryAmount / 100) * pnl).toFixed(2));

                    if(openPosition) {
                        openPosition.candleCount = currentDeal.candleCount;
                        openPosition.closeReverse = currentDeal.closeReverse;
                        openPosition.closeReverseIndex = currentDeal.closeReverseIndex;
                        openPosition.maxReverse = currentDeal.maxReverse;
                        openPosition.maxReverseIndex = currentDeal.maxReverseIndex;
                        openPosition.highPrice = currentDeal.highPrice;
                        openPosition.highPriceIndex = currentDeal.highPriceIndex;
                        openPosition.topNeedle = currentDeal.topNeedle;
                        openPosition.topNeedleIndex = currentDeal.topNeedleIndex;
                        openPosition.firstReverse = currentDeal.firstReverse;
                        openPosition.closePrice = currentDeal.closePrice;
                        openPosition.closeDate = currentDeal.closeDate;
                        openPosition.time = currentDeal.time;
                        openPosition.pnl = currentDeal.pnl;
                        openPosition.realPnL = currentDeal.realPnL;
                        openPosition.highProfit = currentDeal.highProfit;
                        openPosition.mostProfit = currentDeal.mostProfit;       
                    }
                }
            }
        }

        if(beforeComparisonResult !== comparisonResult.position) {
            if(comparisonResult.position === "CLOSE") {
                currentDeal.closeType = comparisonResult.closeType;

                if(openPosition) {
                    openPosition.closeType = currentDeal.closeType;
                }

                currentDeal.status = "CLOSE";
                currentDeal.openPrice = null;
                currentDeal.highPrice = null;
                currentDeal.highPriceIndex = 0;
                currentDeal.topNeedle = null;
                currentDeal.topNeedleIndex = 0;
                currentDeal.posType = "";
                currentDeal.closeReverse = 0;
                currentDeal.closeReverseIndex = 0;
                currentDeal.maxReverse = 0;
                currentDeal.maxReverseIndex = 0;
                currentDeal.firstReverse = 0;
                currentDeal.candleCount = 0;  

                // var olan açık pozisyonu güncelle

                beforeComparisonResult = comparisonResult.position;
                comparisonResult = calculatePositions(lastKline, prevKlines, beforeComparisonResult, currentDeal, analysisOptions, supdemData.symbols[symbol]?.supdem || []);
            }

            if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                currentDeal.key = lastKline.candlestick.closeTime + symbol;
                currentDeal.closeType = 'NC';
                currentDeal.status = comparisonResult.position;
                currentDeal.posType = comparisonResult.posType;
                currentDeal.openPrice = Number(lastKline.candlestick.close);
                currentDeal.highPrice = Number(lastKline.candlestick.close);
                currentDeal.topNeedle = Number(lastKline.candlestick.close);
                currentDeal.closePrice = Number(lastKline.candlestick.close);
                currentDeal.openDate = lastKline.candlestick.closeTime;
                currentDeal.closeDate = lastKline.candlestick.closeTime;
                currentDeal.time = getPassingTime(new Date(lastKline.candlestick.closeTime), new Date(currentDeal.openDate));
                currentDeal.highProfit = 0;
                currentDeal.mostProfit = 0;
                currentDeal.candleCount = 0;
                currentDeal.closeReverse = 0;
                currentDeal.closeReverseIndex = 0;
                currentDeal.maxReverse = 0;
                currentDeal.maxReverseIndex = 0;
                currentDeal.firstReverse = 0;
                currentDeal.pnl = 0;
                currentDeal.realPnL = 0;
                currentDeal.point = comparisonResult.point;
                if(analysisModel === "depthHill") {
                    currentDeal.supportLine = comparisonResult.supportLine;
                }
                
                // yeni pozisyon ekle
                openPositions.push({...currentDeal});
                updatedPositions = openPositions;
            }

            let activeDeal = {
                key: symbol,
                status: '',
                symbol: symbol,
                price: lastKline.candlestick.close,
                date: lastKline.candlestick.closeTime,
                volume: lastKline.candlestick.vol,
                posType: currentDeal.posType,
                closeType: currentDeal.closeType,
                ema20: null, // lastKline.indicators.ema20,
                ema50: null, // lastKline.indicators.ema50,
                ema100: null, // lastKline.indicators.ema100,
                ema200: null, // lastKline.indicators.ema200,
                prevRsi: null, // prevKline.indicators.rsi,
                rsi: null, // lastKline.indicators.rsi,
                bbUp: null, // lastKline.indicators.bollinger.upper,
                bbMid: null, // lastKline.indicators.bollinger.middle,
                bbLow: null, // lastKline.indicators.bollinger.lower,
                strength: currentDeal.point
            };

            if (currentDeal.status === "CLOSE" || currentDeal.status === "LONGCLOSE") {
                activeDeal.status = 'CLOSE POSITION';
            }
            else {
                activeDeal.status = `OPEN ${currentDeal.status}`;
            }
            //console.log('Current Single Deal:', currentDeal);
            activeDeals.push({...activeDeal}); 
        }

        // açık pozisyon varsa eklenen yeni verilerle güncelleme yap.
        if(openPosition) {
            let findIndex = openPositions.findIndex(p => p.symbol === openPosition.symbol);
            openPositions.splice(findIndex, 1, openPosition);
            updatedPositions = openPositions;
        }

        return true;
    });


    return {candlesticks: symbols, positions: updatedPositions, supports: supportResistance, activeDeals: activeDeals };
}

// Short güncellemeleri entegre edilecek.
export const allCurrencyUpdateNewModelAnalysis = async (symbols, analysisModel, supports) => {
    const newDataObj = {};
    const allPastPositionsList = [];

    Object.keys(symbols).map((symbol, i) => {
        let prevKlines = [];
        let beforeComparisonResult = '';

        let currentDeal = {
            key: symbol,
            status: '',
            symbol: symbol,
            openPrice: null,
            highPrice: null,
            closePrice: null,
            openDate: null,
            closeDate:  null,
            posType: null,
            closeType: null
        };

        newDataObj[symbol] = symbols[symbol].map((kline, ix) => {
            //const currentBtcKline = symbols['BTCUSDT'][ix];
            let newObj = {};
            //prevKlines = [...symbols[symbol]].splice(ix - 5, 5);
            //prevKlines = analysisModel === "zombieSpot" ? [...symbols[symbol]].splice(ix - spotPrevKlines, spotPrevKlines) : [...symbols[symbol]].splice(ix - 5, 5);

            if(analysisModel === "zombieSpot") {
                prevKlines = [...symbols[symbol]].splice(ix - spotPrevKlines, spotPrevKlines)
            }
            else if (analysisModel === "depthHill") {
                prevKlines = [...symbols[symbol]].splice(ix - gunstockPrevKlines, gunstockPrevKlines)
            }
            else {
                prevKlines = [...symbols[symbol]].splice(ix - 5, 5); 
            }

            if(ix > 200) {
                let comparisonResult = calculatePositions(kline, prevKlines, beforeComparisonResult, currentDeal);

                beforeComparisonResult = comparisonResult.position;            
                
                if(currentDeal.status === "LONG") {
                    currentDeal.candleCount++;

                    if(currentDeal.openPrice) {
                        const closeMinusPerc = (Number(kline.candlestick.close) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        let tempCloseReverse = currentDeal.closeReverse;
                        currentDeal.closeReverse = closeMinusPerc < tempCloseReverse ? Number(closeMinusPerc.toFixed(2)) : currentDeal.closeReverse;
                        currentDeal.closeReverseIndex = closeMinusPerc < tempCloseReverse ? currentDeal.candleCount : currentDeal.closeReverseIndex;
    
                        const maxMinusPerc = (Number(kline.candlestick.low) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        let tempMaxReverse = currentDeal.maxReverse;
                        currentDeal.maxReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        currentDeal.maxReverseIndex = maxMinusPerc < tempMaxReverse ? currentDeal.candleCount : currentDeal.maxReverseIndex;

                        currentDeal.highPriceIndex = Number(kline.candlestick.close) > currentDeal.highPrice ? currentDeal.candleCount : currentDeal.highPriceIndex;
                        currentDeal.highPrice = Number(kline.candlestick.close) > currentDeal.highPrice ? Number(kline.candlestick.close) : currentDeal.highPrice;
                        currentDeal.topNeedleIndex = Number(kline.candlestick.high) > currentDeal.topNeedle ? currentDeal.candleCount : currentDeal.topNeedleIndex;
                        currentDeal.topNeedle = Number(kline.candlestick.high) > currentDeal.topNeedle ? Number(kline.candlestick.high) : currentDeal.topNeedle;
                        if(currentDeal.candleCount === 1) {
                            currentDeal.firstReverse = maxMinusPerc < tempMaxReverse ? Number(maxMinusPerc.toFixed(2)) : currentDeal.maxReverse;
                        } 
                    }
                }
                else if (currentDeal.status === "SHORT") {
                    if(currentDeal.openPrice) {
                        const closeMinusPerc = (Number(kline.candlestick.close) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        currentDeal.closeReverse = -1 * closeMinusPerc < currentDeal.closeReverse ? Number((-1 * closeMinusPerc).toFixed('2')) : currentDeal.closeReverse;
    
                        const maxMinusPerc = (Number(kline.candlestick.high) - currentDeal.openPrice) * 100 / currentDeal.openPrice;
                        currentDeal.maxReverse = -1 * maxMinusPerc < currentDeal.maxReverse ? Number((-1 * maxMinusPerc).toFixed('2')) : currentDeal.maxReverse;
    
                        currentDeal.highPrice = Number(kline.candlestick.close) < currentDeal.highPrice ? Number(kline.candlestick.close) : currentDeal.highPrice;
                        currentDeal.topNeedle = Number(kline.candlestick.high) < currentDeal.topNeedle ? Number(kline.candlestick.high) : currentDeal.topNeedle;    
                    }
                }

                if(currentDeal.status !== comparisonResult.position) {     
            
                    if(currentDeal.status !== '' && comparisonResult.position === "CLOSE") {
                        currentDeal.key = ix + symbol;
                        currentDeal.closePrice = Number(kline.candlestick.close);
                        currentDeal.closeDate = kline.candlestick.closeTime;
                        //currentDeal.time = (new Date(kline.candlestick.closeTime).getTime() - new Date(currentDeal.openDate).getTime()) / (1000 * 60 * 60);
                        currentDeal.time = getPassingTime(new Date(kline.candlestick.closeTime), new Date(currentDeal.openDate));

                        let pnl = Number(((kline.candlestick.close - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                        pnl = pnl < -5 ? -5 : pnl;

                        currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                        currentDeal.closeType = comparisonResult.closeType;
                        currentDeal.highProfit = Number(((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                        currentDeal.mostProfit = Number(((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
    
                        // all position object push
                        allPastPositionsList.push({...currentDeal});
                        currentDeal.status = "CLOSE";
                        currentDeal.openPrice = null;
                        currentDeal.highPrice = null;
                        currentDeal.highPriceIndex = 0;
                        currentDeal.topNeedle = null;
                        currentDeal.topNeedleIndex = 0;
                        currentDeal.posType = "";
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.candleCount = 0;                        
                    }
    
                    if(comparisonResult.position === "LONG" || comparisonResult.position === "SHORT") {
                        currentDeal.closeType = null;
                        currentDeal.status = comparisonResult.position;
                        currentDeal.posType = comparisonResult.posType;
                        currentDeal.openPrice = Number(kline.candlestick.close);
                        currentDeal.highPrice = Number(kline.candlestick.close);
                        currentDeal.topNeedle = Number(kline.candlestick.close);
                        currentDeal.closePrice = null;
                        currentDeal.openDate = kline.candlestick.closeTime;
                        currentDeal.closeDate = null;
                        currentDeal.time = null;
                        currentDeal.highProfit = null;
                        currentDeal.mostProfit = null;
                        currentDeal.candleCount = 0;
                        currentDeal.closeReverse = 0;
                        currentDeal.closeReverseIndex = 0;
                        currentDeal.maxReverse = 0;
                        currentDeal.maxReverseIndex = 0;
                        currentDeal.firstReverse = 0;
                        currentDeal.pnl = null;                  
                    }
                }

                if(ix === symbols[symbol].length - 1 && currentDeal.status !== "CLOSE" && currentDeal.status !== '') {
                    currentDeal.key = kline.candlestick.closeTime + symbol; 

                    currentDeal.closePrice = Number(kline.candlestick.close);
                    currentDeal.closeDate = kline.candlestick.closeTime;
                    //currentDeal.time = (new Date(kline.candlestick.closeTime).getTime() - new Date(currentDeal.openDate).getTime()) / (1000 * 60 * 60);
                    currentDeal.time = getPassingTime(new Date(kline.candlestick.closeTime), new Date(currentDeal.openDate));

                    let pnl = Number(((kline.candlestick.close - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
    
                    pnl = pnl < -5 ? -5 : pnl;
                    currentDeal.pnl = currentDeal.status === "SHORT" ? -1 * pnl : pnl; 
                    currentDeal.highProfit = Number(((currentDeal.highPrice - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
                    currentDeal.mostProfit = Number(((currentDeal.topNeedle - currentDeal.openPrice) * 100 / currentDeal.openPrice).toFixed(2));
    
                    currentDeal.closeType = "NC";
    
                    allPastPositionsList.push({...currentDeal});           
                }

                newObj.position = {
                    status: currentDeal.status,
                    openDate: currentDeal.openDate,
                    openPrice: currentDeal.openPrice,
                    highPrice: currentDeal.highPrice,
                    highPriceIndex: currentDeal.highPriceIndex,
                    posType: currentDeal.posType,
                    closeType: currentDeal.closeType,
                    candleCount: currentDeal.candleCount,
                    closeReverse: currentDeal.closeReverse,
                    closeReverseIndex: currentDeal.closeReverseIndex,
                    maxReverse: currentDeal.maxReverse,
                    maxReverseIndex: currentDeal.maxReverseIndex,
                    topNeedle: currentDeal.topNeedle,
                    topNeedleIndex: currentDeal.topNeedleIndex,
                    firstReverse: currentDeal.firstReverse
                }
            }

            return {...kline, ...newObj};
        });

        return true;
    });

    return {candlesticks: newDataObj, positions: allPastPositionsList };
}

// Bottaki versiyona göre yeniden düzenlenecek.
const movingAverage = (data, lastKline) => {
    let fixedValue;
    let sum;
    let length;

    let splitPrice = data[0].candlestick.close.toString().split('.');
    fixedValue = splitPrice[1] ? splitPrice[1].length : 0;

    if(lastKline) {  
        //data.length === 4 && console.log('mAvgData', data, lastKline[4]); 
        let priceArray = data.map(x => x.candlestick.close);
        priceArray.push(lastKline.close);
        //data.length === 4 && console.log(symbol, priceArray);
        length = priceArray.length;
        sum = priceArray.reduce((a, b) => {
            return Number(a) + Number(b);   
        });
        //data.length === 4 && console.log('ma5:', symbol, priceArray, sum, length);
    }
    else {
        length = data.length;
        sum = data.map(x => x.candlestick.close).reduce((a, b) => {
            return Number(a) + Number(b);
        });
    }

    return Number((sum / length).toFixed(fixedValue));
}

const obvMovingAverage = (data) => {
    let sum;
    let length;

    length = data.length;
    sum = data.reduce((a, b) => {
        return Number(a) + Number(b);
    });

    return Number(sum / length);
}

const rsiMovingAverage = (data) => {
    console.log('rsi data array', data);
    let sum;
    let length;

    length = data.length;
    sum = data.reduce((a, b) => {
        return Number(a) + Number(b);
    });

    return Number(sum / length);
}

const expMovingAverage = (beforeEma, activeClosePrice, period, tick) => {
    //const splitPrice = activeClosePrice.toString().split('.');
    //const fixedValue = splitPrice[1] ? splitPrice[1].length : 0;    

    return Number((activeClosePrice * (2 / (period + 1)) + (beforeEma * (1 - (2 / (period + 1))))).toFixed(tick));
    //let multiplier = Number(String(1).padEnd((tick + 1), 0));
    //let calculatedValue = Number((activeClosePrice * (2 / (period + 1)) + (beforeEma * (1 - (2 / (period + 1))))));
    //return Math.round((calculatedValue + Number.EPSILON) * multiplier) / multiplier
}

const firstRsiAvgCalculate = (data) => {
    let greenCandlesticks = [];
    let redCandlesticks = [];

    data.map((kline, i) => {
        if(i > 0) {
            let prevKline = data[i - 1];

            let currVal = kline.candlestick.close - prevKline.candlestick.close;
            if(currVal > 0) {
                greenCandlesticks.push(currVal);
            }
            else {
                redCandlesticks.push(-1 * currVal);
            }
        }
    });

    let greenAverage;
    if(greenCandlesticks.length > 0) {
        const greenTotal = greenCandlesticks.reduce((a, b) => {
            return Number(a) + Number(b);
        });
    
        greenAverage = greenTotal / rsiValue; 
    }
    else {
        greenAverage = 0;
    }
    
    let redAverage;
    if(redCandlesticks.length > 0) {
        const redTotal = redCandlesticks.reduce((a, b) => {
            return Number(a) + Number(b);
        });
    
        redAverage = redTotal / rsiValue;
    }
    else {
        redAverage = 0;
    }

    return {greenAvg: greenAverage, redAvg: redAverage};
}

const relativeStrengthIndex = (prevGreenAvg, prevRedAvg, activeKline, prevKline) => {
    //console.log(`prevGreen: ${prevGreenAvg}, prevRed: ${prevRedAvg}, kline: ${activeKline}`);
    let greenAverage;
    let redAverage;

    let currVal = activeKline.candlestick.close - prevKline.candlestick.close;

    if(currVal > 0) {
        //console.log('currVal artı: ', currVal);
        greenAverage = ((prevGreenAvg * (rsiValue - 1)) + currVal) / rsiValue;
        redAverage = ((prevRedAvg * (rsiValue - 1)) + 0) / rsiValue;
    }
    else {
        //console.log('currVal eksi: ', currVal);
        greenAverage = ((prevGreenAvg * (rsiValue - 1)) + 0) / rsiValue;
        redAverage = ((prevRedAvg * (rsiValue - 1)) + (-1 * currVal)) / rsiValue;
    }

    const rsi = Number((100 - (100 / (1 + (greenAverage / redAverage)))).toFixed(2))

    return {greenAvg: greenAverage, redAvg: redAverage, rsi: rsi};
}

const onBalanceVolume = (prevObv, prevKline, lastKline, ix) => {
    let currentObv = 0;
    if(lastKline.candlestick.close > prevKline.candlestick.close) {
        currentObv = prevObv + Number(lastKline.candlestick.vol);
    }
    else if(lastKline.candlestick.close < prevKline.candlestick.close) {
        currentObv = prevObv - Number(lastKline.candlestick.vol);
    }
    else {
        currentObv = prevObv;
    }
    return currentObv;
}

const checkPivot = (prevCandles, nextCandles, kline, pivotSource) => {
    let pivotLow = "";
    let pivotHigh = "";
    if(pivotSource === 'close') {
        let prevCloses = prevCandles.map(p => p.candlestick.close);
        let nextCloses = nextCandles.map(p => p.candlestick.close);
        let prevHighestPrice = Math.max(...[...prevCloses]);
        let prevLowestPrice = Math.min(...[...prevCloses]);
    
        let nextHighestPrice = Math.max(...[...nextCloses]);
        let nextLowestPrice = Math.min(...[...nextCloses]);
    
        if(kline.candlestick.close >= prevHighestPrice && 
            kline.candlestick.close > nextHighestPrice) {
            pivotHigh = "high";
        }
        
        if (kline.candlestick.close <= prevLowestPrice && 
                kline.candlestick.close < nextLowestPrice) {
            pivotLow = "low";
        }    
    }
    else {
        // TODO: Bu koşul söz konusu olduğunda aynı mum hem high hem de low pivot point olabiliyor bişey düşüneceğiz. şu anda low ise high olmuyor.
        let prevHighs = prevCandles.map(p => p.candlestick.high);
        let nextHighs = nextCandles.map(p => p.candlestick.high);
        let prevLows = prevCandles.map(p => p.candlestick.low);
        let nextLows = nextCandles.map(p => p.candlestick.low);
    
        let prevHighestPrice = Math.max(...[...prevHighs]);
        let prevLowestPrice = Math.min(...[...prevLows]);
    
        let nextHighestPrice = Math.max(...[...nextHighs]);
        let nextLowestPrice = Math.min(...[...nextLows]);
    
        if(kline.candlestick.high >= prevHighestPrice && 
            kline.candlestick.high > nextHighestPrice) {
            pivotHigh = "high";
        }
        
        if (kline.candlestick.low <= prevLowestPrice && 
                kline.candlestick.low < nextLowestPrice) {
            pivotLow = "low";
        }
    }

    return {pivotHigh, pivotLow}
};

const findSupportResistance = (klines, symbol, analysisOptions, filterStepCount = 14, autoSimplification = true) => {    
    const filterParams = {
        backBarCount: analysisOptions.backBar,
        numberOfCandles: analysisOptions.pivotPeriod,
        filterStepCount: filterStepCount,
        autoSimplification: autoSimplification
    };
    let newKlines = [...klines].splice(klines.length - filterParams.backBarCount, filterParams.backBarCount);

    //console.log('first kline', newKlines[0]);
    let closePrices = newKlines.map(p => p.candlestick.close);

    let highestPrice = Math.max(...[...closePrices]);
    let lowestPrice = Math.min(...[...closePrices]);
    let channelWidth = (highestPrice - lowestPrice) * 100 / lowestPrice;

    console.log('channel Width', symbol, channelWidth);

    let simplification = 0;
    if(filterParams.autoSimplification) {
        if(channelWidth < 5)
            simplification = 25
        else if(channelWidth >= 5 && channelWidth < 7)
            simplification = 35
        else if(channelWidth >= 7 && channelWidth < 10)
            simplification = 70;
        else if(channelWidth >= 10 && channelWidth < 20)
            simplification = 100;
        else if(channelWidth > 20 && channelWidth < 40)
            simplification = 150;
        else if(channelWidth >= 40 && channelWidth < 100)
            simplification = 250;
        else
            simplification = 500;
    }
    else {
        simplification = filterParams.filterStepCount;
    }

    //console.log('first kline', symbol, newKlines[0], simplification);
    
    let simplifiArray = [];
    for (let i = 1; i <= simplification; i++) {
        let val = 1.0000 + 0.0001 * i;
        simplifiArray.push(val);
    }

    let lines = [];
    let temp_lines = [];

    newKlines.map((p, i) => {
        if(p.candlestick.pivot !== "" && p.candlestick.pivot !== undefined && i + filterParams.numberOfCandles <= newKlines.length) {
            lines.push({strength: 1, price: p.candlestick.close});
        }
    });

    lines.sort((a, b) => a.price - b.price);
    //console.log('lines', symbol, lines);
    
    for (let i = 0; i < simplifiArray.length; i++) {
        let whileCondition = false;
        do {
            let matched_index = -1;
            lines.sort((a, b) => a.price - b.price);
            whileCondition = false;

            for (let ix = 0; ix < lines.length; ix++) {
                let nextLine = lines.length > ix + 1 ? lines[ix + 1] : 0;
                let diffPercent = lines[ix].price * simplifiArray[i];

                if(nextLine.price > 0 && diffPercent > nextLine.price && lines[ix].price !== nextLine.price) {
                    whileCondition = true;
                    let newLine = (lines[ix].price + nextLine.price) / 2;
                    temp_lines.push({strength: lines[ix].strength + 1, price: newLine});
                    matched_index = ix + 1;
                }
                else {
                    if(ix !== matched_index && lines[ix].price !== nextLine.price) {
                        temp_lines.push(lines[ix]);
                    }
                }
            }
            lines = [...temp_lines];
            temp_lines = [];           
        }
        while (whileCondition);  
    }

    return lines;
};

const findLowTrendChannels = (highPivots, kline, currentIndex, lastChannelFirstPoint, lastChannelFirstIndex, activeChannelCurrentPoint, pivotSource) => {
    if(highPivots.length > 0) {
        const reverseArray = [...highPivots].reverse();
        const prevPivotHigh = reverseArray[0].value;
        const prevPivotIndex = reverseArray[0].index;
        const twoPrevPivotHigh = reverseArray.length > 1 ? reverseArray[1].value : 0;
        const twoPrevPivotIndex = reverseArray.length > 1 ? reverseArray[1].index : 0;
        const threePrevPivotHigh = reverseArray.length > 2 ? reverseArray[2].value : 0;
        const klineClose = pivotSource === 'close' ? kline.candlestick.close : kline.candlestick.high;
        const klineOpen = kline.candlestick.open;

        /*if(lastChannelFirstPoint > klineClose * 1.001 && klineClose >= activeChannelCurrentPoint && twoPrevPivotHigh === lastChannelFirstPoint) {
            // VAROLAN BİR TREND KANALINI BAŞLANGIÇ NOKTASI AYNI KALACAK ŞEKİLDE FARKLI AÇIYLA YENİDEN ÇİZME;
            underflowCount = underflowCount + 1;
            underflow = false;
            return {prevPivotIndex: lastChannelFirstIndex, prevPivotHigh: lastChannelFirstPoint, currentPivotIndex: currentIndex, currentPivotHigh: klineClose, type: 'changed'}

        }
        else*/ if(twoPrevPivotHigh >= threePrevPivotHigh && prevPivotHigh < twoPrevPivotHigh && klineClose * 1.001 < prevPivotHigh && (extendPercentageCalculate(kline.trends.down.top, 'plus', 0.35) < klineClose || extendPercentageCalculate(kline.trends.down.bottom, 'minus', 0.35) > klineClose)) {
            // SIFIRDAN YENİ BİR TREND KANALI OLUŞTURMA;
            underflowCount = 0;
            underflow = false;
            return {prevPivotIndex: twoPrevPivotIndex, prevPivotHigh: twoPrevPivotHigh, currentPivotIndex: currentIndex, currentPivotHigh: klineClose, type: 'original' }
        }

        /*
        if(underflowCount === 0 && underflow && kline.trends.down.top > klineClose && kline.trends.down.bottom < klineClose && kline.trends.down.bottom < klineOpen) {
            // VAROLAN KANAL AÇISI DEĞİŞTİRİLMEDEN GENİŞLETİLİYOR;
            underflow = false;
            underflowCount = underflowCount + 1;
            return {prevPivotIndex: lastChannelFirstIndex, prevPivotHigh: lastChannelFirstPoint, currentPivotIndex: currentIndex, currentPivotHigh: kline.trends.down.top, type: 'extended'}
        }
        */

        return null;
    }
}

const findHighTrendChannels = (lowPivots, kline, currentIndex, lastChannelFirstPoint, lastChannelFirstIndex, activeChannelCurrentPoint, pivotSource) => {
    if(lowPivots.length > 0) {
        const reverseArray = [...lowPivots].reverse();
        const prevPivotLow = reverseArray[0].value;
        const prevPivotIndex = reverseArray[0].index;
        const twoPrevPivotLow = reverseArray.length > 1 ? reverseArray[1].value : 0;
        const twoPrevPivotIndex = reverseArray.length > 1 ? reverseArray[1].index : 0;
        const threePrevPivotLow = reverseArray.length > 2 ? reverseArray[2].value : 0;
        const klineClose = pivotSource === 'close' ? kline.candlestick.close : kline.candlestick.low;
        const klineOpen = kline.candlestick.open;

        /*if(lastChannelFirstPoint < klineClose * 0.999 && klineClose <= activeChannelCurrentPoint && twoPrevPivotLow === lastChannelFirstPoint) {
            // VAROLAN BİR TREND KANALINI BAŞLANGIÇ NOKTASI AYNI KALACAK ŞEKİLDE FARKLI AÇIYLA YENİDEN ÇİZME;
            overflowCount = overflowCount + 1;
            overflow = false;
            return {prevPivotIndex: lastChannelFirstIndex, prevPivotLow: lastChannelFirstPoint, currentPivotIndex: currentIndex, currentPivotLow: klineClose, type: 'changed'}

        }
        else */if(twoPrevPivotLow <= threePrevPivotLow && prevPivotLow > twoPrevPivotLow && klineClose * 0.999 > prevPivotLow && (extendPercentageCalculate(kline.trends.up.bottom, 'minus', 0.35) > klineClose || extendPercentageCalculate(kline.trends.up.top, 'plus', 0.35) < klineClose)) {
            // SIFIRDAN YENİ BİR TREND KANALI OLUŞTURMA;
            overflowCount = 0;
            overflow = false;
            return {prevPivotIndex: twoPrevPivotIndex, prevPivotLow: twoPrevPivotLow, currentPivotIndex: currentIndex, currentPivotLow: klineClose, type: 'original' }
        }

        /*if(overflowCount === 0 && overflow && kline.trends.up.bottom < klineClose && kline.trends.up.top > klineClose && kline.trends.up.top > klineOpen) {
            // VAROLAN KANAL AÇISI DEĞİŞTİRİLMEDEN GENİŞLETİLİYOR;
            overflow = false;
            overflowCount = overflowCount + 1;
            return {prevPivotIndex: lastChannelFirstIndex, prevPivotLow: lastChannelFirstPoint, currentPivotIndex: currentIndex, currentPivotLow: kline.trends.up.bottom, type: 'extended'}
        }*/
        return null;
    }
}

const findSupplyDemandAreas = (kline, prevKlines, supdemAreas) => {

    //console.log('findSupplyDemandAreas', demandAreas);

    let supdemArea = undefined;
    let invalidSupDemAreas = [];

    let prev1Kline = [...prevKlines][prevKlines.length - 1];
    let prev2Kline = [...prevKlines][prevKlines.length - 2];
    let prev3Kline = [...prevKlines][prevKlines.length - 3];

    let currentCandleBody = calculateBetweenPercentage(kline.candlestick.close, kline.candlestick.open);
    let prevCandleBody = calculateBetweenPercentage(prev1Kline.candlestick.close, prev1Kline.candlestick.open);
    let twoPrevCandleBody = calculateBetweenPercentage(prev2Kline.candlestick.close, prev2Kline.candlestick.open);
    //console.log('prev candlestick', kline, prev1Kline, prev2Kline, prev3Kline, prevKlines);
    let threePrevCandleBody = calculateBetweenPercentage(prev3Kline.candlestick.close, prev3Kline.candlestick.open);
    let threePrevCandleLowWick = prev3Kline.candlestick.close > prev3Kline.candlestick.open ? calculateBetweenPercentage(prev3Kline.candlestick.open, prev3Kline.candlestick.low) : calculateBetweenPercentage(prev3Kline.candlestick.close, prev3Kline.candlestick.low);
    
    let prevCandleHasImbalanceDemand = calculateBetweenPercentage(kline.candlestick.low, prev2Kline.candlestick.high);
    let twoPrevCandleHasImbalanceDemand = calculateBetweenPercentage(prev1Kline.candlestick.low, prev3Kline.candlestick.high);
    
    let prevCandleHasImbalanceSupply = calculateBetweenPercentage(prev2Kline.candlestick.low, kline.candlestick.high)
    let twoPrevCandleHasImbalanceSupply = calculateBetweenPercentage(prev3Kline.candlestick.low, prev1Kline.candlestick.high)

    // Karar mumu gövdesi %2'den küçük ya da eşit, alt iğnesi gövdesinden büyük
    let decisionCandle = Math.abs(threePrevCandleBody) <= 2 //and threePrevCandleLowWick > threePrevCandleBody

    // Karar mumu alt iğnesi sonraki mumun alt iğnesinden daha aşağıda, karar mumunun üst iğnesi sonraki mumun üst iğnesinden daha aşağıda
    let decisionAndNextCandleWickCorrelationDemand = prev3Kline.candlestick.low <= prev2Kline.candlestick.low && prev3Kline.candlestick.high < prev2Kline.candlestick.high;
    // Sonraki mumun gövdesi karar mumunun gövdesibdeb büyük, sonraki mum yeşil
    let decisionAndNextCandleBodyCorrelationDemand = twoPrevCandleBody >= threePrevCandleBody && prev2Kline.candlestick.close > prev2Kline.candlestick.open;
    // Sonraki mum da imbalance var, imbalance boşluğu mumun kendi gövdesinin yarısına eşit ya da daha büyük, imbalance mumunun gövdesi en az %4, imbalance mumu karar mumunun gövdesinin 3 katına eşit ya da daha büyük
    let nextCandleImbalanceCheckDemand = twoPrevCandleHasImbalanceDemand > 0 && twoPrevCandleHasImbalanceDemand >= twoPrevCandleBody / 3 && twoPrevCandleBody >= 4 && threePrevCandleBody * 3 <= twoPrevCandleBody;
    // İki sonraki mum da imbalance var, imbalance boşluğu mumun kendi gövdesinin yarısına eşit ya da daha büyük, imbalance mumunun gövdesi en az %4, imbalance mumu karar mumunun gövdesinin 3 katına eşit ya da daha büyük
    let nextTwoCandleImbalanceCheckDemand = prevCandleHasImbalanceDemand > 0 && prevCandleHasImbalanceDemand >= prevCandleBody / 3 && prevCandleBody >= 4 && threePrevCandleBody * 3 <= prevCandleBody;

    let demandAreaCondition = decisionCandle && decisionAndNextCandleWickCorrelationDemand && decisionAndNextCandleBodyCorrelationDemand && (nextCandleImbalanceCheckDemand || nextTwoCandleImbalanceCheckDemand);

    if(demandAreaCondition) {
        //console.log('DEMAND AREA ', prev3Kline.candlestick.high, prev3Kline.candlestick.low, prev3Kline.candlestick.localOpenTime);
        supdemArea = {
            high: prev3Kline.candlestick.high,
            mid: Number((prev3Kline.candlestick.high - (prev3Kline.candlestick.high - prev3Kline.candlestick.low) / 2).toFixed(4)),
            low: prev3Kline.candlestick.low,
            openTime: prev3Kline.candlestick.openTime,
            localTime: prev3Kline.candlestick.localOpenTime,
            activationDate: kline.candlestick.closeTime, 
            expiredDate: 9999999999999,
            type: 'demand',
            state: 'active'
        }
    }

    // Karar mumu alt iğnesi sonraki mumun alt iğnesinden daha aşağıda, karar mumunun üst iğnesi sonraki mumun üst iğnesinden daha aşağıda
    let decisionAndNextCandleWickCorrelationSupply = prev3Kline.candlestick.high >= prev2Kline.candlestick.high && prev3Kline.candlestick.low > prev2Kline.candlestick.low;
    // Sonraki mumun gövdesi karar mumunun gövdesinden büyük, sonraki mum kırmızı
    let decisionAndNextCandleBodyCorrelationSupply = Math.abs(twoPrevCandleBody) >= Math.abs(threePrevCandleBody) && prev2Kline.candlestick.close < prev2Kline.candlestick.open;
    // Sonraki mum da imbalance var, imbalance boşluğu mumun kendi gövdesinin yarısına eşit ya da daha büyük, imbalance mumunun gövdesi en az %4, imbalance mumu karar mumunun gövdesinin 3 katına eşit ya da daha büyük
    let nextCandleImbalanceCheckSupply = twoPrevCandleHasImbalanceSupply > 0 && Math.abs(twoPrevCandleBody) >= 4 && twoPrevCandleHasImbalanceSupply >= Math.abs(twoPrevCandleBody) / 3 && Math.abs(threePrevCandleBody) * 3 <= Math.abs(twoPrevCandleBody);
    // İki sonraki mum da imbalance var, imbalance boşluğu mumun kendi gövdesinin yarısına eşit ya da daha büyük, imbalance mumunun gövdesi en az %4, imbalance mumu karar mumunun gövdesinin 3 katına eşit ya da daha büyük
    let nextTwoCandleImbalanceCheckSupply = prevCandleHasImbalanceSupply > 0 && Math.abs(prevCandleBody) >= 4 && prevCandleHasImbalanceSupply >= Math.abs(prevCandleBody) / 3 && Math.abs(threePrevCandleBody) * 3 <= Math.abs(prevCandleBody);

    let supplyAreaCondition = decisionCandle && decisionAndNextCandleWickCorrelationSupply && decisionAndNextCandleBodyCorrelationSupply && (nextCandleImbalanceCheckSupply || nextTwoCandleImbalanceCheckSupply);

    if(supplyAreaCondition) {
        //console.log('SUPPLY AREA ', prev3Kline.candlestick.high, prev3Kline.candlestick.low, prev3Kline.candlestick.localOpenTime);
        supdemArea = {
            high: prev3Kline.candlestick.high,
            mid: Number((prev3Kline.candlestick.high - (prev3Kline.candlestick.high - prev3Kline.candlestick.low) / 2).toFixed(4)),
            low: prev3Kline.candlestick.low,
            openTime: prev3Kline.candlestick.openTime,
            localTime: prev3Kline.candlestick.localOpenTime,
            activationDate: kline.candlestick.closeTime, 
            expiredDate: 9999999999999,
            type: 'supply',
            state: 'active'
        }
    }



    if(supdemAreas.length > 0) {
        supdemAreas.map(d => {
            if(d.state === "active" && d.type === "demand" && kline.candlestick.close < d.low && prev1Kline.candlestick.close < d.low) {
                invalidSupDemAreas.push(d);
            }
            if(d.state === "active" && d.type === "supply" && kline.candlestick.close > d.high && prev1Kline.candlestick.close > d.high) {
                invalidSupDemAreas.push(d);
            }
        });
    }

    return { supdemArea, invalidSupDemAreas };

    /*
    float currentCandleBody = calc_percent(close[0], open[0])
    float prevCandleBody = calc_percent(close[1], open[1])
    float twoPrevCandleBody = calc_percent(close[2], open[2])
    float threePrevCandleBody = calc_percent(close[3], open[3])
    float threePrevCandleLowWick = close[3] > open[3] ? calc_percent(open[3], low[3]) : calc_percent(close[3], low[3])
    float prevCandleHasImbalance = calc_percent(low, high[2])
    float twoPrevCandleHasImbalance = calc_percent(low[1], high[3])
    */

    //console.log('findSupplyDemandAreas', kline, prev1Kline, prev2Kline, prev3Kline, prevKlines);
    // kendisi de dahil önceki mumlar ile karşılaştırmalar yaparak suğğly ve demand bölgeleri belirlenecek.
}


// get current active positions
export const allCurrencyCandlestickFilter = async (symbols, supports) => {
    let deals = {};
    //let currentDeals = [];
    let currentEma20Deals = [];
    let currentRsiUp30Deals = [];
    let currentRsiDown30Deals = [];
    let currentEma200Deals = [];
    let currentEma100Deals = [];
    let currentSrDeals = [];
    let currentUpwardExpansions = [];
    let currentTrendBreaks = [];

    Object.keys(symbols).map((symbol, i) => {
        if(true) {            
            //let currencyInfo = symbolList.find(x => x.symbol === symbol);
            let lastKline = symbols[symbol].candles[symbols[symbol].candles.length - 1];
            let prevKline = symbols[symbol].candles[symbols[symbol].candles.length - 2];
            let prevSixtyKlines = [...symbols[symbol].candles].splice(symbols[symbol].candles.length - 61, 60);

            /*
            const currentPosition = lastKline.position.status;
            const prevPosition = prevKline.position.status;

            if(prevPosition !== currentPosition) {
                let currentDeal = {
                    key: symbol,
                    status: '',
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    date: lastKline.candlestick.closeTime,
                    volume: lastKline.candlestick.vol,
                    posType: lastKline.position.posType,
                    closeType: lastKline.position.closeType,
                    ema20: lastKline.indicators.ema20,
                    ema50: lastKline.indicators.ema50,
                    ema100: lastKline.indicators.ema100,
                    ema200: lastKline.indicators.ema200,
                    prevRsi: prevKline.indicators.rsi,
                    rsi: lastKline.indicators.rsi,
                    BbTop: lastKline.indicators.bollinger.bbUp,
                    BbMiddle: lastKline.indicators.bollinger.bbMid,
                    BbBottom: lastKline.indicators.bollinger.bbLow
                };

                if (currentPosition === "CLOSE" || currentPosition === "LONGCLOSE") {
                    currentDeal.status = 'CLOSE POSITION';
                }
                else {
                    currentDeal.status = `OPEN ${currentPosition}`;
                }
                //console.log('Current Single Deal:', currentDeal);
                currentDeals.push({...currentDeal}); 
            }
            */
/*
            if(calculateEma20Test(lastKline)) {
                let currentEma20Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close
                }

                currentEma20Deals.push(currentEma20Deal);
            }     
            
            if(calculateRsiUpThirthy(prevKline, lastKline)) {
                let currentRsiUp30Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    rsi: lastKline.indicators.rsi
                }

                currentRsiUp30Deals.push(currentRsiUp30Deal);
            }  
            
            if(calculateRsiDownThirthy(lastKline)) {
                let currentRsiDown30Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    rsi: lastKline.indicators.rsi
                }

                currentRsiDown30Deals.push(currentRsiDown30Deal);
            }

            const ema200Control = calculateEma200(prevKline, lastKline);

            if(ema200Control.condition) {
                let currentEma200Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    type: ema200Control.type
                }

                currentEma200Deals.push(currentEma200Deal);
            }

            const ema100Control = calculateEma100(prevKline, lastKline);

            if(ema100Control.condition) {
                let currentEma100Deal = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                    type: ema100Control.type
                }

                currentEma100Deals.push(currentEma100Deal);
            }

            const SrControl = calculateSupRes(prevKline, lastKline, supports[symbol]);

            if(SrControl.condition) {
                let currentSrDeal = {
                    key: symbol,
                    symbol: symbol,
                    image: SrControl.image,
                    line: SrControl.line,
                    strength: SrControl.strength,
                    prevLine: SrControl.prevLine,
                    nextLine: SrControl.nextLine
                }

                currentSrDeals.push(currentSrDeal);
            }

            if(calculateUpwardExpansion(prevSixtyKlines, lastKline)) {
                let currentUpwardExpansion = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                }

                currentUpwardExpansions.push(currentUpwardExpansion);
            }

            if(calculateTrendBreaks(prevKline, lastKline)) {
                let currentTrendBreak = {
                    key: symbol,
                    symbol: symbol,
                    price: lastKline.candlestick.close,
                }

                currentTrendBreaks.push(currentTrendBreak);         
            }
*/
            deals = {
                //currentDeals: currentDeals.sort((a, b) => ('' + a.symbol).localeCompare(b.symbol)),
                currentEma20Deals,
                currentRsiUp30Deals,
                currentRsiDown30Deals,
                currentEma200Deals,
                currentEma100Deals,
                currentSrDeals,
                currentUpwardExpansions,
                currentTrendBreaks
            };
        }

        return true;
    });

    return deals;
}

export const allCurrencyProfitLossGroupAndFilter = (allPositions, simulator, analysisModel) => {
    let allProfitLossList = []; 

    const groupBySymbols = allPositions.reduce((group, position) => {
        const { symbol } = position;
        group[symbol] = group[symbol] ?? [];
        group[symbol].push(position);
        return group;
      }, {});

      Object.keys(groupBySymbols).map((symbol, i) => {
        //console.log(symbol);
        let currencyHistoricalData = {};
        /*
        groupBySymbols[symbol].map((pos, i) => {
            console.log(pos, i);
        });
        */
        //console.log(symbol, groupBySymbols[symbol]);

        let posArray = [];
        let negArray = [];
        let notrArray = [];
        let totalProfits;
        let totalLoss;
        let mostProfit;
        let mostLoss;
        let totalRealProfits;
        let totalRealLoss;

        console.log(analysisModel);
        if(simulator || analysisModel === "depthHill" || analysisModel === "explosionLoose") {
            posArray = groupBySymbols[symbol].filter(x => x.pnl > 0.1);
            negArray = groupBySymbols[symbol].filter(x => x.pnl < 0);
            notrArray = groupBySymbols[symbol].filter(x => x.pnl <= 0.1 && x.pnl >= 0);
            totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
            mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
            totalRealProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].realPnL : posArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
            totalRealLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].realPnL : negArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
            
        }
        else if(analysisModel === "rsiDivergence" || analysisModel === "TC" || analysisModel === "SD") {
            posArray = groupBySymbols[symbol].filter(x => x.pnl > 0);
            negArray = groupBySymbols[symbol].filter(x => x.pnl < 0);
            notrArray = groupBySymbols[symbol].filter(x => x.pnl === 0);
            totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].pnl : posArray.map(x => x.pnl).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
            mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
            totalRealProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].realPnL : posArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
            totalRealLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].realPnL : negArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
        }
        else {
            posArray = groupBySymbols[symbol].filter(x => x.mostProfit > 1);
            negArray = groupBySymbols[symbol].filter(x => x.mostProfit <= 1);
            totalProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => a + b).toFixed(2) : 0;
            totalLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => a + b).toFixed(2) : 0;
            mostProfit = posArray.length > 0 ? posArray.length === 1 ? posArray[0].mostProfit : posArray.map(x => x.mostProfit).reduce((a, b) => b > a ? b : a).toFixed(2) : 0;
            mostLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].pnl : negArray.map(x => x.pnl).reduce((a, b) => b > a ? a : b).toFixed(2) : 0;
            totalRealProfits = posArray.length > 0 ? posArray.length === 1 ? posArray[0].realPnL : posArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
            totalRealLoss = negArray.length > 0 ? negArray.length === 1 ? negArray[0].realPnL : negArray.map(x => x.realPnL).reduce((a, b) => a + b).toFixed(2) : 0;
        }
        
        currencyHistoricalData.key = symbol;
        currencyHistoricalData.symbol = symbol;
        currencyHistoricalData.totalPosProcess = posArray.length;
        currencyHistoricalData.totalNegProcess = negArray.length;
        currencyHistoricalData.totalNotrProcess = notrArray.length;
        currencyHistoricalData.totalProfits = totalProfits;
        currencyHistoricalData.totalLoss = totalLoss;
        currencyHistoricalData.pnlRatio = (Number(totalProfits) + Number(totalLoss)).toFixed(2);
        currencyHistoricalData.mostProfit = mostProfit;
        currencyHistoricalData.mostLoss = mostLoss;
        currencyHistoricalData.totalRealProfits = totalRealProfits;
        currencyHistoricalData.totalRealLoss = totalRealLoss;
        currencyHistoricalData.realPnlRatio = (Number(totalRealProfits) + Number(totalRealLoss)).toFixed(2);


        allProfitLossList.push(currencyHistoricalData);
      });

      //console.log('all profit loss list', allProfitLossList);

      return allProfitLossList;
}

const calculatePositions = (kline, prevKlines, beforeComparison, currentDeal, analysisOptions, supDemAreas) => {
    let comparisonResult = {};
    //console.log('POS TYPE', currentDeal.posType);
    let prevKline = [...prevKlines][prevKlines.length - 1];
    let twoPrevKline = [...prevKlines][prevKlines.length - 2];

    //let lastKlinePercentage = kline.change;
    //let topNeedleVolumeComparison = kline.candlestick.high - kline.candlestick.close < kline.candlestick.close - kline.candlestick.open; 

    let longPoint = 0;
    let shortPoint = 0;
    let type = 0;

    if(beforeComparison === "CLOSE" || beforeComparison === "") {
        let modelType = "";

        if(analysisOptions.model === "AI") {
            modelType = "AI";
            // pozisyon açılış koşulları yazılacak.
            //let emaPoints = calculateEmaPoints(twoPrevKline, prevKline, kline);
            //longPoint = longPoint + emaPoints.longPoint;
            //shortPoint = shortPoint + emaPoints.shortPoint;

            //let rsiOversPoints = calculateRsiOversPoints(twoPrevKline, prevKline, kline);
            //longPoint = longPoint + rsiOversPoints.longPoint;
            //shortPoint = shortPoint + rsiOversPoints.shortPoint;

            //let rsiDivergences = calculateRsiDivergencePoints(kline, prevKline, twoPrevKline, prevKlines, lastPivot, analysisOptions.pivotPeriod);
            //longPoint = longPoint + rsiDivergences.longPoint;
            //shortPoint = shortPoint + rsiDivergences.shortPoint;

            //let srLines = calculateSrLinesPoints(kline, prevKline, twoPrevKline, prevKlines, supports);
            //longPoint = longPoint + srLines.longPoint;
            //shortPoint = shortPoint + srLines.shortPoint;   

            //let fakeSetup = calculateFakeSetupPoints(kline, prevKline, twoPrevKline);
            //longPoint = fakeSetup.longPoint;
            //shortPoint = fakeSetup.shortPoint;

            //let upwardExpansion = calculateUpwardExpansionPoints(prevKlines, kline);
            //longPoint = upwardExpansion.longPoint;
            //shortPoint = upwardExpansion.shortPoint;

            //let aboveEma21 = calculateEma21Points(kline);
            //longPoint = aboveEma21.longPoint;
            //shortPoint = aboveEma21.shortPoint;
        }
        else if(analysisOptions.model === "SD") {
            modelType = "SD";      
            let openSupDem = calculateSupplyDemandArea(kline, prevKline, supDemAreas, analysisOptions.processDirection);
            longPoint = longPoint + openSupDem.longPoint;
            shortPoint = shortPoint + openSupDem.shortPoint;
            type = openSupDem.type;      
        }

        if(longPoint > shortPoint && longPoint >= 30) {
            comparisonResult.position = "LONG";
            comparisonResult.posType = `${modelType}-${type}`; 
            comparisonResult.point = longPoint;
        }
        else if (shortPoint > longPoint && shortPoint >= 30) {
            comparisonResult.position = "SHORT";
            comparisonResult.posType = `${modelType}-${type}`; 
            comparisonResult.point = shortPoint;       
        }
        else {
            comparisonResult.position = beforeComparison;          
        }
    }
    else {
        if(!analysisOptions.noClosing) {

            let currentTopNeedle = currentDeal.topNeedle > kline.candlestick.high ? currentDeal.topNeedle : kline.candlestick.high;
            let currentBottomNeedle = kline.candlestick.low < currentDeal.topNeedle ? kline.candlestick.low : currentDeal.topNeedle;    
            let posTimeLimit = analysisOptions.posTimeLimit ? (currentDeal.candleCount + 1) >= analysisOptions.posTimeLimit : 0;

            // TODO: Entry Stop ve Trailing uyumu için buraya bi ayarlamalar gerekiyor.
            if(beforeComparison === "LONG") {
                const closeLongEntryStop = analysisOptions.entryStop ? currentTopNeedle >= currentDeal.openPrice * (1  + (analysisOptions.entryStop / 100)) && kline.candlestick.close <= currentDeal.openPrice : 0;

                const closeLongTakeProfit = analysisOptions.takeProfit ? currentTopNeedle >= currentDeal.openPrice * (1  + (analysisOptions.takeProfit / 100)) : 0;

                const closeLongStopLoss = analysisOptions.stopLoss ? kline.candlestick.low < currentDeal.openPrice * (1 - (analysisOptions.stopLoss / 100)) :  0;

                const closeLongTrailingStop = analysisOptions.trailingActivation && analysisOptions.trailingCallback ? currentTopNeedle >= currentDeal.openPrice * (1  + (analysisOptions.trailingActivation / 100)) && kline.candlestick.close <= currentTopNeedle * (1 - (analysisOptions.trailingCallback / 100)) : 0;
                
                if(closeLongTakeProfit) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-TP";
                }
                else if(closeLongTrailingStop) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-TS";
                }
                else if(closeLongEntryStop) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-ES";
                }
                else if(closeLongStopLoss) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-SL";
                }
                else if(posTimeLimit) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-TL";
                }
                else {
                    comparisonResult.position = beforeComparison;  
                }
            }
            else if (beforeComparison === "SHORT") {
                const closeLongEntryStop = analysisOptions.entryStop ? currentBottomNeedle <= currentDeal.openPrice * (1  - (analysisOptions.entryStop / 100)) && kline.candlestick.close >= currentDeal.openPrice : 0;

                const closeShortTakeProfit = analysisOptions.takeProfit ? kline.candlestick.low < currentDeal.openPrice * (1  - (analysisOptions.takeProfit / 100)) : 0;

                const closeShortStopLoss = analysisOptions.stopLoss ? kline.candlestick.high > currentDeal.openPrice * (1 + (analysisOptions.stopLoss / 100)) :  0;

                const closeShortTrailingStop = analysisOptions.trailingActivation && analysisOptions.trailingCallback ? currentBottomNeedle <= currentDeal.openPrice * (1  - (analysisOptions.trailingActivation / 100)) && kline.candlestick.close >= currentBottomNeedle * (1 + (analysisOptions.trailingCallback / 100)) : 0;
                
                if(closeShortTakeProfit) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-TP";
                }
                else if(closeShortTrailingStop) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-TS";
                }
                else if(closeLongEntryStop) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-ES";
                }
                else if(closeShortStopLoss) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-SL";
                }
                else if(posTimeLimit) {
                    comparisonResult.position = "CLOSE";
                    comparisonResult.closeType = "CP-TL";
                }
                else {
                    comparisonResult.position = beforeComparison;  
                }
            }
            else {
                comparisonResult.position = beforeComparison;         
            }
        }
    }

    return comparisonResult;
}

// emalar yanlış hesaplanıyor.
const calculateEmaPoints = (twoPrevKline, prevKline, kline) => {
    let longPoint = 0;
    let shortPoint = 0;

    //Long
    if(kline.candlestick.open < kline.indicators.ema21 && kline.candlestick.close > kline.indicators.ema21) {
        longPoint = longPoint + 3;
    }

    if(kline.candlestick.open < kline.indicators.ema50 && kline.candlestick.close > kline.indicators.ema50) {
        longPoint = longPoint + 3;
    }

    if(kline.candlestick.open < kline.indicators.ema100 && kline.candlestick.close > kline.indicators.ema100) {
        longPoint = longPoint + 3;
    }

    if(kline.candlestick.open < kline.indicators.ema200 && kline.candlestick.close > kline.indicators.ema200) {
        longPoint = longPoint + 3;
    }

    if(prevKline.candlestick.open > prevKline.indicators.ema21 && prevKline.candlestick.close < prevKline.indicators.ema21) {
        longPoint = longPoint + 3;
    }

    if(prevKline.candlestick.open > prevKline.indicators.ema50 && prevKline.candlestick.close < prevKline.indicators.ema50) {
        longPoint = longPoint + 3;
    }

    if(prevKline.candlestick.open > prevKline.indicators.ema100 && prevKline.candlestick.close < prevKline.indicators.ema100) {
        longPoint = longPoint + 3;
    }

    if(prevKline.candlestick.open > prevKline.indicators.ema200 && prevKline.candlestick.close < prevKline.indicators.ema200) {
        longPoint = longPoint + 3;
    }

    if(kline.candlestick.open > kline.indicators.ema21 && kline.candlestick.close > kline.indicators.ema21 && kline.candlestick.low < kline.indicators.ema21) {
        longPoint = longPoint + 6;
    }

    if(kline.candlestick.open > kline.indicators.ema50 && kline.candlestick.close > kline.indicators.ema50 && kline.candlestick.low < kline.indicators.ema50) {
        longPoint = longPoint + 6;
    }

    if(kline.candlestick.open > kline.indicators.ema100 && kline.candlestick.close > kline.indicators.ema100 && kline.candlestick.low < kline.indicators.ema100) {
        longPoint = longPoint + 6;
    }

    if(kline.candlestick.open > kline.indicators.ema200 && kline.candlestick.close > kline.indicators.ema200 && kline.candlestick.low < kline.indicators.ema200) {
        longPoint = longPoint + 6;
    }

    if(twoPrevKline.indicators.ema21 < twoPrevKline.indicators.ema50 && prevKline.indicators.ema21 >= prevKline.indicators.ema50 && kline.indicators.ema21 > kline.indicators.ema50) {
        longPoint = longPoint + 6;
    }

    if(twoPrevKline.indicators.ema21 < twoPrevKline.indicators.ema100 && prevKline.indicators.ema21 >= prevKline.indicators.ema100 && kline.indicators.ema21 > kline.indicators.ema100) {
        longPoint = longPoint + 6;
    }

    if(twoPrevKline.indicators.ema21 < twoPrevKline.indicators.ema200 && prevKline.indicators.ema21 >= prevKline.indicators.ema200 && kline.indicators.ema21 > kline.indicators.ema200) {
        longPoint = longPoint + 6;
    }

    if(twoPrevKline.indicators.ema50 < twoPrevKline.indicators.ema100 && prevKline.indicators.ema50 >= prevKline.indicators.ema100 && kline.indicators.ema50 > kline.indicators.ema100) {
        longPoint = longPoint + 6;
    }

    if(twoPrevKline.indicators.ema50 < twoPrevKline.indicators.ema200 && prevKline.indicators.ema50 >= prevKline.indicators.ema200 && kline.indicators.ema50 > kline.indicators.ema200) {
        longPoint = longPoint + 6;
    }

    if(twoPrevKline.indicators.ema100 < twoPrevKline.indicators.ema200 && prevKline.indicators.ema100 >= prevKline.indicators.ema200 && kline.indicators.ema100 > kline.indicators.ema200) {
        longPoint = longPoint + 6;
    }

    //Short
    if(kline.candlestick.open > kline.indicators.ema21 && kline.candlestick.close < kline.indicators.ema21) {
        shortPoint = shortPoint + 3;
    }

    if(kline.candlestick.open > kline.indicators.ema50 && kline.candlestick.close < kline.indicators.ema50) {
        shortPoint = shortPoint + 3;
    }

    if(kline.candlestick.open > kline.indicators.ema100 && kline.candlestick.close < kline.indicators.ema100) {
        shortPoint = shortPoint + 3;
    }

    if(kline.candlestick.open > kline.indicators.ema200 && kline.candlestick.close < kline.indicators.ema200) {
        shortPoint = shortPoint + 3;
    }

    if(prevKline.candlestick.open < prevKline.indicators.ema21 && prevKline.candlestick.close > prevKline.indicators.ema21) {
        shortPoint = shortPoint + 3;
    }

    if(prevKline.candlestick.open < prevKline.indicators.ema50 && prevKline.candlestick.close > prevKline.indicators.ema50) {
        shortPoint = shortPoint + 3;
    }

    if(prevKline.candlestick.open < prevKline.indicators.ema100 && prevKline.candlestick.close > prevKline.indicators.ema100) {
        shortPoint = shortPoint + 3;
    }

    if(prevKline.candlestick.open < prevKline.indicators.ema200 && prevKline.candlestick.close > prevKline.indicators.ema200) {
        shortPoint = shortPoint + 3;
    }

    if(kline.candlestick.open < kline.indicators.ema21 && kline.candlestick.close < kline.indicators.ema21 && kline.candlestick.high > kline.indicators.ema21) {
        shortPoint = shortPoint + 6;
    }

    if(kline.candlestick.open < kline.indicators.ema50 && kline.candlestick.close < kline.indicators.ema50 && kline.candlestick.high > kline.indicators.ema50) {
        shortPoint = shortPoint + 6;
    }

    if(kline.candlestick.open < kline.indicators.ema100 && kline.candlestick.close < kline.indicators.ema100 && kline.candlestick.high > kline.indicators.ema100) {
        shortPoint = shortPoint + 6;
    }

    if(kline.candlestick.open < kline.indicators.ema200 && kline.candlestick.close < kline.indicators.ema200 && kline.candlestick.high > kline.indicators.ema200) {
        shortPoint = shortPoint + 6;
    }

    if(twoPrevKline.indicators.ema21 > twoPrevKline.indicators.ema50 && prevKline.indicators.ema21 <= prevKline.indicators.ema50 && kline.indicators.ema21 < kline.indicators.ema50) {
        shortPoint = shortPoint + 6;
    }

    if(twoPrevKline.indicators.ema21 > twoPrevKline.indicators.ema100 && prevKline.indicators.ema21 <= prevKline.indicators.ema100 && kline.indicators.ema21 < kline.indicators.ema100) {
        shortPoint = shortPoint + 6;
    }

    if(twoPrevKline.indicators.ema21 > twoPrevKline.indicators.ema200 && prevKline.indicators.ema21 <= prevKline.indicators.ema200 && kline.indicators.ema21 < kline.indicators.ema200) {
        shortPoint = shortPoint + 6;
    }

    if(twoPrevKline.indicators.ema50 > twoPrevKline.indicators.ema100 && prevKline.indicators.ema50 <= prevKline.indicators.ema100 && kline.indicators.ema50 < kline.indicators.ema100) {
        shortPoint = shortPoint + 6;
    }

    if(twoPrevKline.indicators.ema50 > twoPrevKline.indicators.ema200 && prevKline.indicators.ema50 <= prevKline.indicators.ema200 && kline.indicators.ema50 < kline.indicators.ema200) {
        shortPoint = shortPoint + 6;
    }

    if(twoPrevKline.indicators.ema100 > twoPrevKline.indicators.ema200 && prevKline.indicators.ema100 <= prevKline.indicators.ema200 && kline.indicators.ema100 < kline.indicators.ema200) {
        shortPoint = shortPoint + 6;
    }

    return {longPoint, shortPoint};
}

const calculateRsiOversPoints = (twoPrevKline, prevKline, kline) => {
    let longPoint = 0;
    let shortPoint = 0;

    //Long
    if(kline.indicators.rsi < 30) {
        longPoint++;
    }

    if(prevKline.indicators.rsi < 30 && kline.indicators.rsi < 30 && prevKline.indicators.rsi > kline.indicators.rsi) {
        longPoint = longPoint + 2;
    }

    if(prevKline.indicators.rsi < 30 && kline.indicators.rsi > 30) {
        longPoint = longPoint + 2;
    }

    if(twoPrevKline.indicators.rsi < 30 && prevKline.indicators.rsi < 30 && kline.indicators.rsi < 30 && twoPrevKline.indicators.rsi > prevKline.indicators.rsi && prevKline.indicators.rsi > kline.indicators.rsi) {
        longPoint = longPoint + 3;
    }

    if(twoPrevKline.indicators.rsi < 30 && prevKline.indicators.rsi < 30 && kline.indicators.rsi > 30 && twoPrevKline.indicators.rsi > prevKline.indicators.rsi) {
        longPoint = longPoint + 3;
    }

    if(twoPrevKline.indicators.rsi < 30 && prevKline.indicators.rsi > 30 && kline.indicators.rsi > 30 && prevKline.indicators.rsi < kline.indicators.rsi) {
        longPoint = longPoint + 3;
    }

    //Short
    if(kline.indicators.rsi > 70) {
        shortPoint++;
    }

    if(prevKline.indicators.rsi > 70 && kline.indicators.rsi > 70 && prevKline.indicators.rsi < kline.indicators.rsi) {
        shortPoint = shortPoint + 2;
    }

    if(prevKline.indicators.rsi > 70 && kline.indicators.rsi < 70) {
        shortPoint = shortPoint + 2;
    }

    if(twoPrevKline.indicators.rsi > 70 && prevKline.indicators.rsi > 70 && kline.indicators.rsi > 70 && twoPrevKline.indicators.rsi < prevKline.indicators.rsi && prevKline.indicators.rsi < kline.indicators.rsi) {
        shortPoint = shortPoint + 3;
    }

    if(twoPrevKline.indicators.rsi > 70 && prevKline.indicators.rsi > 70 && kline.indicators.rsi < 70 && twoPrevKline.indicators.rsi < prevKline.indicators.rsi) {
        shortPoint = shortPoint + 3;
    }

    if(twoPrevKline.indicators.rsi > 70 && prevKline.indicators.rsi > 70 && kline.indicators.rsi > 70 && prevKline.indicators.rsi > kline.indicators.rsi) {
        shortPoint = shortPoint + 3;
    }

    return {longPoint, shortPoint}
}

const calculateRsiDivergencePoints = (kline, prevKline, twoPrevKline, prevKlines, lastPivot, pivotPeriod) => {
    let longPoint = 0;
    let shortPoint = 0;

    let cuttedPrevKlines = prevKlines.splice(prevKlines.length - (pivotPeriod + 2), (pivotPeriod + 2));
    //console.log('prevs:', cuttedPrevKlines, kline);


    let prevRsiValues = cuttedPrevKlines.slice(rsiDivergenceType === "tight" ? 0 : 1, pivotPeriod).map(c => c.indicators.rsi);
    let lowestRsi = Math.min(...[...prevRsiValues]);
    let highestRsi = Math.max(...[...prevRsiValues]);

    let looseLongRegularExtra = 0;
    let looseLongHiddenExtra = 0;
    let looseShortRegularExtra = 0;
    let looseShortHiddenExtra = 0;

    if(lastPivot && lastPivot.length > 2) {
        if(prevKline.candlestick.close * 1.001 < lastPivot[0].indicators.close) {
            looseLongRegularExtra++;
            looseShortHiddenExtra++;
        }
        if(prevKline.indicators.rsi > lastPivot[0].indicators.rsi + 1) {
            looseLongRegularExtra++;
            looseShortHiddenExtra++;
        }
        if(prevKline.candlestick.close > lastPivot[0].candlestick.close * 1.001) {
            looseLongHiddenExtra++;
            looseShortRegularExtra++;
        }
        if(prevKline.indicators.rsi + 1 < lastPivot[0].indicators.rsi) {
            looseLongHiddenExtra++;
            looseShortRegularExtra++;
        }
        if(lastPivot[2].candlestick.close > lastPivot[1].candlestick.close) {
            looseLongRegularExtra++;
            looseLongHiddenExtra++;
        }
        if(lastPivot[2].candlestick.close < lastPivot[1].candlestick.close) {
            looseShortRegularExtra++;
            looseShortHiddenExtra++;
        }
    }

    //Long
    let tightLongControl = prevKline.candlestick.color === "green" &&
                           twoPrevKline.candlestick.color === "red" &&
                           kline.candlestick.color === "green" &&
                           twoPrevKline.indicators.rsi <= lowestRsi &&
                           prevKline.candlestick.close < twoPrevKline.candlestick.open &&
                           twoPrevKline.candlestick.pivot === "low" &&
                           prevKline.candlestick.change < 1 &&
                           kline.candlestick.change < 1 &&
                           lastPivot && lastPivot.length > 1 &&
                           lastPivot[0].candlestick.pivot === "low" && 
                           //prevKline.candlestick.close > twoPrevKline.candlestick.close; // ek deneme
                           kline.candlestick.close > twoPrevKline.candlestick.close;

    let tightLongRegular = lastPivot && lastPivot.length > 1 &&
                           twoPrevKline.candlestick.close < lastPivot[0].candlestick.close &&
                           twoPrevKline.indicators.rsi > lastPivot[0].indicators.rsi &&
                           lastPivot[0].indicators.rsi < 50;//33;

    let tightLongHidden = lastPivot && lastPivot.length > 1 &&
                          twoPrevKline.candlestick.close > lastPivot[0].candlestick.close &&
                          twoPrevKline.indicators.rsi < lastPivot[0].indicators.rsi &&
                          twoPrevKline.indicators.rsi < 50;//33;

    let looseLongControl = kline.candlestick.color === "green" &&
                           prevKline.candlestick.color === "red" &&
                           prevKline.indicators.rsi < lowestRsi &&
                           kline.candlestick.close < prevKline.candlestick.open &&
                           prevKline.candlestick.pivot === "low" &&
                           //kline.candlestick.change < 0.5 &&
                           lastPivot && lastPivot.length > 1 &&
                           lastPivot[0].candlestick.pivot === "low";

    let looseLongRegular = lastPivot && lastPivot.length > 1 &&
                           prevKline.candlestick.close < lastPivot[0].candlestick.close &&
                           prevKline.indicators.rsi > lastPivot[0].indicators.rsi &&
                           lastPivot[0].indicators.rsi < 50 &&//33;
                           looseLongRegularExtra >= 3;
    
    let looseLongHidden = lastPivot && lastPivot.length > 1 &&
                          prevKline.candlestick.close > lastPivot[0].candlestick.close &&
                          prevKline.indicators.rsi < lastPivot[0].indicators.rsi &&
                          prevKline.indicators.rsi < 50 &&//33;
                          looseLongHiddenExtra >= 3;

    // Short
    let tightShortControl = prevKline.candlestick.color === "red" &&
                            twoPrevKline.candlestick.color === "green" &&
                            kline.candlestick.color === "red" &&
                            twoPrevKline.indicators.rsi >= highestRsi &&
                            prevKline.candlestick.close > twoPrevKline.candlestick.open &&
                            twoPrevKline.candlestick.pivot === "high" &&
                            prevKline.candlestick.change > -1 &&
                            kline.candlestick.change > -1 &&
                            lastPivot && lastPivot.length > 1 &&
                            lastPivot[0].candlestick.pivot === "high" && 
                            twoPrevKline.candlestick.close > kline.candlestick.close;

    let tightShortRegular = lastPivot && lastPivot.length > 1 &&
                            twoPrevKline.candlestick.close > lastPivot[0].candlestick.close &&
                            twoPrevKline.indicators.rsi < lastPivot[0].indicators.rsi &&
                            lastPivot[0].indicators.rsi > 50;//67;

    let tightShortHidden = lastPivot && lastPivot.length > 1 &&
                           twoPrevKline.candlestick.close < lastPivot[0].candlestick.close &&
                           twoPrevKline.indicators.rsi > lastPivot[0].indicators.rsi &&
                           twoPrevKline.indicators.rsi > 50;//67;

    let looseShortControl = kline.candlestick.color === "red" &&
                            prevKline.candlestick.color === "green" &&
                            prevKline.indicators.rsi > highestRsi &&
                            kline.candlestick.close > prevKline.candlestick.open &&
                            prevKline.candlestick.pivot === "high" &&
                            //kline.candlestick.change > -0.5 &&
                            lastPivot && lastPivot.length > 1 &&
                            lastPivot[0].candlestick.pivot === "high";

    let looseShortRegular = lastPivot && lastPivot.length > 1 &&
                            prevKline.candlestick.close > lastPivot[0].candlestick.close &&
                            prevKline.indicators.rsi < lastPivot[0].indicators.rsi &&
                            lastPivot[0].indicators.rsi > 50 &&//67;
                            looseShortRegularExtra >= 3;

    let looseShortHidden = lastPivot && lastPivot.length > 1 &&
                           prevKline.candlestick.close < lastPivot[0].candlestick.close &&
                           prevKline.indicators.rsi > lastPivot[0].indicators.rsi &&
                           prevKline.indicators.rsi > 50 &&//67;
                           looseShortHiddenExtra >= 3;

    if(rsiDivergenceType === "tight") {
        if(lastPivot && lastPivot.length > 1 && lastPivot[1].candlestick.close > lastPivot[0].candlestick.close && tightLongControl && (tightLongRegular || tightLongHidden)) {
            //console.log('rsi', prevKlines.length, prevRsiValues, twoPrevKline.indicators.rsi);
            longPoint = longPoint + 30;
        }

        if(lastPivot && lastPivot.length > 1 && lastPivot[1].candlestick.close < lastPivot[0].candlestick.close &&  tightShortControl && (tightShortRegular || tightShortHidden)) {
            shortPoint = shortPoint + 30;
            //console.log('lastPivots', lastPivot, lastPivot[0], lastPivot[1]);
        }
    }
    else {
        if(lastPivot && lastPivot.length > 1 && lastPivot[1].candlestick.close > lastPivot[0].candlestick.close && looseLongControl && (looseLongRegular || looseLongHidden)) {
            longPoint = longPoint + 30;
        }

        if(lastPivot && lastPivot.length > 1 && lastPivot[1].candlestick.close < lastPivot[0].candlestick.close && looseShortControl && (looseShortRegular || looseShortHidden)) {
            shortPoint = shortPoint + 30;
        }    
    }

    return {longPoint, shortPoint}
}

const calculateFakeSetupPoints = (kline, prevKline, twoPrevKline) => {
    let longPoint = 0;
    let shortPoint = 0;

    const insideBar = prevKline.candlestick.high < twoPrevKline.candlestick.high && prevKline.candlestick.low > twoPrevKline.candlestick.low;
    const pinBarBear = kline.candlestick.high > twoPrevKline.candlestick.high &&
                       kline.candlestick.low >= twoPrevKline.candlestick.low &&
                       (prevKline.candlestick.color === "green" ? kline.candlestick.close <= prevKline.candlestick.close : kline.candlestick.close <= prevKline.candlestick.open);

    const pinBarBull = kline.candlestick.low < twoPrevKline.candlestick.low &&
                       kline.candlestick.high <= twoPrevKline.candlestick.high &&
                       (prevKline.candlestick.color === "green" ? kline.candlestick.close >= prevKline.candlestick.open : kline.candlestick.close >= prevKline.candlestick.close);

    if(insideBar && pinBarBear) {
        shortPoint = 100;
    }

    if(insideBar && pinBarBull) {
        longPoint = 100;
    }
    return {longPoint, shortPoint}
}

const calculateSrLinesPoints = (kline, prevKline, twoPrevKline, prevKlines, supports) => {
    let longPoint = 0;
    let shortPoint = 0;

    let activeLines = supports.filter(l => l.price >= kline.candlestick.low && l.price <= kline.candlestick.high);
    let prevKlineActiveLines = supports.filter(l => l.price >= prevKline.candlestick.low && l.price <= prevKline.candlestick.high);
    
    let activeBottomLine;
    let activeTopLine;
    if(activeLines.length > 0) {
        if(activeLines.length === 1) {
            activeBottomLine = activeLines[0];
            activeTopLine = activeLines[0];       
        }
        else {
            activeBottomLine = activeLines[0];
            activeTopLine = activeLines[activeLines.length - 1];           
        }
    }

    let activePrevBottomLine;
    let activePrevTopLine;
    if(prevKlineActiveLines.length > 0) {
        if(prevKlineActiveLines.length === 1) {
            activePrevBottomLine = prevKlineActiveLines[0];
            activePrevTopLine = prevKlineActiveLines[0];       
        }
        else {
            activePrevBottomLine = prevKlineActiveLines[0];
            activePrevTopLine = prevKlineActiveLines[prevKlineActiveLines.length - 1];           
        }
    }


    //console.log('active lines', activeLines);
    if(activeLines.length > 0) {
        if(kline.candlestick.color === "green") {
            // model 1:
            if(kline.candlestick.close > activeBottomLine.price && kline.candlestick.open < activeBottomLine.price &&
               prevKline.candlestick.color === "green" && prevKline.candlestick.close < activeBottomLine.price && prevKline.candlestick.open < activeBottomLine.price &&
               twoPrevKline.candlestick.color === "red" && twoPrevKline.candlestick.close < activeBottomLine.price && twoPrevKline.candlestick.open > activeBottomLine.price) {
                longPoint = longPoint + 30;
            }
            /*else if(kline.candlestick.low < activeBottomLine.price && kline.candlestick.open > activeBottomLine.price &&
                prevKline.candlestick.color === "red" && prevKline.candlestick.low < activeBottomLine.price && prevKline.candlestick.close > activeBottomLine.price &&
                kline.indicators.rsi < 35) {
                longPoint = longPoint + 60;
            }*/
        }
    }
    else if(prevKlineActiveLines.length > 0) {
        if(kline.candlestick.color === "green") {
            // model 2:
            /*if(kline.candlestick.close > activePrevBottomLine.price && kline.candlestick.low > activePrevBottomLine.price &&
                prevKline.candlestick.low < activePrevBottomLine.price && prevKline.candlestick.close > activePrevBottomLine.price &&
                kline.indicators.rsi < 35) {
                longPoint = longPoint + 55;
            }*/

            if(kline.candlestick.close > activePrevBottomLine.price &&
                prevKline.candlestick.low < activePrevBottomLine.price && prevKline.candlestick.open > activePrevBottomLine.price && 
                prevKline.candlestick.close > activePrevBottomLine.price) {
                longPoint = longPoint + 30;
            }
        }
    }


    // FARKLI LINE MODELLERI DE ANALIZ EDİLİP EKLENECEK.


            /*
            if(kline.candlestick.close > activeBottomLine.price && kline.candlestick.open < activeBottomLine.price) {
                longPoint = longPoint + 25;
            }
            else if(kline.candlestick.close > activeBottomLine.price && kline.candlestick.open > activeBottomLine.price) {
                longPoint = longPoint + 10;
            }
            else if(kline.candlestick.low < activeBottomLine.price && kline.candlestick.close > activeBottomLine.price) {
                longPoint = longPoint + 25;
            }

            if(prevKline.candlestick.color === "green") {
                if(prevKline.candlestick.close > activeBottomLine.price && prevKline.candlestick.open < activeBottomLine.price) {
                    longPoint = longPoint + 20;
                }
                else if(prevKline.candlestick.close < activeBottomLine.price && prevKline.candlestick.open < activeBottomLine.price) {
                    longPoint = longPoint + 10;
                }

                if(twoPrevKline.candlestick.color === "red") {
                    if(twoPrevKline.candlestick.close < activeBottomLine.price && twoPrevKline.candlestick.open > activeBottomLine.price) {
                        longPoint = longPoint + 20;
                    }
                }
            }
            else {
                if(prevKline.candlestick.close < activeBottomLine.price && prevKline.candlestick.open > activeBottomLine.price) {
                    longPoint = longPoint + 20;
                }          
            }
            */

    return {longPoint, shortPoint}
}

const calculateUpwardExpansionPoints = (prevKlines, kline) => {
    let cuttedPrevKlines = prevKlines.splice(prevKlines.length - 60, 60);
    console.log('prevs:', cuttedPrevKlines, kline);

    let longPoint = 0;
    let shortPoint = 0;
    let closePrices = cuttedPrevKlines.map(p => p.candlestick.close);

    let highestPrice = Math.max(...[...closePrices]);
    let lowestPrice = Math.min(...[...closePrices]);
    let channelWidth = (highestPrice - lowestPrice) * 100 / lowestPrice;

    if(kline.candlestick.close > highestPrice) {
        let newChannelWidth = (kline.candlestick.close - lowestPrice) * 100 / lowestPrice;
        if(channelWidth < 4 && newChannelWidth > 4) {
            longPoint = 100;
        }
    }

    return {longPoint, shortPoint}
}

const calculateEma21Points = (kline) => {
    let longPoint = 0;
    let shortPoint = 0;

    if(kline.candlestick.close > kline.indicators.ema21) {
        longPoint = 100;
    }

    return {longPoint, shortPoint}
}

const calculateTrendLinesPoints = (kline, prevKline, twoPrevKline, prevKlines) => {
    let longPoint = 0;
    let shortPoint = 0;
    let type = '';

    let prev1Kline = [...prevKlines][prevKlines.length - 1];
    let prev2Kline = [...prevKlines][prevKlines.length - 2];
    let prev3Kline = [...prevKlines][prevKlines.length - 3];
    let prev4Kline = [...prevKlines][prevKlines.length - 4];
    let prev5Kline = [...prevKlines][prevKlines.length - 5];
    let prev6Kline = [...prevKlines][prevKlines.length - 6];
    let prev7Kline = [...prevKlines][prevKlines.length - 7];
    let prev8Kline = [...prevKlines][prevKlines.length - 8];
    let prev9Kline = [...prevKlines][prevKlines.length - 9];
    let prev10Kline = [...prevKlines][prevKlines.length - 10];

    let isPrevKlineInsideDownChannel = prevKline.candlestick.close < prevKline.trends.down.top && prevKline.candlestick.close > prevKline.trends.down.bottom && prevKline.candlestick.open < prevKline.trends.down.top && prevKline.candlestick.open > prevKline.trends.down.bottom;
    let isTwoPrevKlineInsideDownChannel = twoPrevKline.candlestick.close < twoPrevKline.trends.down.top && twoPrevKline.candlestick.close > twoPrevKline.trends.down.bottom && twoPrevKline.candlestick.open < twoPrevKline.trends.down.top && twoPrevKline.candlestick.open > twoPrevKline.trends.down.bottom;
    let isPrevKlineInsideUpChannel = prevKline.candlestick.close < prevKline.trends.up.top && prevKline.candlestick.close > prevKline.trends.up.bottom && prevKline.candlestick.open < prevKline.trends.up.top && prevKline.candlestick.open > prevKline.trends.up.bottom;
    let isTwoPrevKlineInsideUpChannel = twoPrevKline.candlestick.close < twoPrevKline.trends.up.top && twoPrevKline.candlestick.close > twoPrevKline.trends.up.bottom && twoPrevKline.candlestick.open < twoPrevKline.trends.up.top && twoPrevKline.candlestick.open > twoPrevKline.trends.up.bottom;;

    let klineOverflowUpChannelDown = kline.candlestick.close > extendPercentageCalculate(kline.trends.down.top, 'plus', 1) && kline.candlestick.close < extendPercentageCalculate(kline.trends.down.top, 'plus', 20) && kline.candlestick.open < kline.trends.down.top && kline.candlestick.open > kline.trends.down.bottom;
    let klineOverflowUpChannelUp = kline.candlestick.close > extendPercentageCalculate(kline.trends.up.top, 'plus', 1) && kline.candlestick.close < extendPercentageCalculate(kline.trends.up.top, 'plus', 20) && kline.candlestick.open < kline.trends.up.top && kline.candlestick.open > kline.trends.up.bottom;
    
    let klineChange = kline.candlestick.change;

    let prevKlineOverflowUpChannelDown = prevKline.candlestick.close > prevKline.trends.down.top && prevKline.candlestick.close < extendPercentageCalculate(prevKline.trends.down.top, 'plus', 1) && prevKline.candlestick.open < prevKline.trends.down.top && prevKline.candlestick.open > prevKline.trends.down.bottom;
    let twoPrevKlineOverflowUpChannelDown = twoPrevKline.candlestick.close > twoPrevKline.trends.down.top && twoPrevKline.candlestick.close < extendPercentageCalculate(twoPrevKline.trends.down.top, 'plus', 1) &&  twoPrevKline.candlestick.open < twoPrevKline.trends.down.top && twoPrevKline.candlestick.open > twoPrevKline.trends.down.bottom;

    let prevKlineOverflowUpChannelUp = prevKline.candlestick.close > prevKline.trends.up.top && prevKline.candlestick.close < extendPercentageCalculate(prevKline.trends.up.top, 'plus', 1) && prevKline.candlestick.open < prevKline.trends.up.top && prevKline.candlestick.open > prevKline.trends.up.bottom;
    let twoPrevKlineOverflowUpChannelUp = twoPrevKline.candlestick.close > twoPrevKline.trends.up.top && twoPrevKline.candlestick.close < extendPercentageCalculate(twoPrevKline.trends.up.top, 'plus', 1) && twoPrevKline.candlestick.open < twoPrevKline.trends.up.top && twoPrevKline.candlestick.open > twoPrevKline.trends.up.bottom;

    let klineOutsideUpChannelDown = kline.candlestick.close > kline.trends.down.top && kline.candlestick.open > kline.trends.down.top && kline.candlestick.change > 1 && kline.candlestick.change < 3;
    let klineOutsideUpChannelUp = kline.candlestick.close > kline.trends.up.top && kline.candlestick.open > kline.trends.up.top && kline.candlestick.change > 1 && kline.candlestick.change < 3;
    
    /*
    if(klineOverflowUpChannelDown && isPrevKlineInsideDownChannel && isTwoPrevKlineInsideDownChannel) {
        longPoint = 100;
    } /*
    else if(prevKlineOverflowUpChannelDown && klineOutsideUpChannelDown && prevKline.candlestick.change < 5) {
        longPoint = 90;
    }
    else if(twoPrevKlineOverflowUpChannelDown && klineOutsideUpChannelDown && twoPrevKline.candlestick.change < 5) {
        longPoint = 80;
    }*/
    /*
    if(klineOverflowUpChannelUp && isPrevKlineInsideUpChannel && isTwoPrevKlineInsideUpChannel) {
        longPoint = 100;
    }/*
    else if(prevKlineOverflowUpChannelUp && klineOutsideUpChannelUp && prevKline.candlestick.change < 5) {
        longPoint = 90;
    }
    else if(twoPrevKlineOverflowUpChannelUp && klineOutsideUpChannelUp && twoPrevKline.candlestick.change < 5) {
        longPoint = 80;
    }*/

    let klineOverflowDownChannelDown = kline.candlestick.close < extendPercentageCalculate(kline.trends.down.bottom, 'minus', 1) && kline.candlestick.open < kline.trends.down.top && kline.candlestick.open > kline.trends.down.bottom;
    let klineOverflowDownChannelUp = kline.candlestick.close < extendPercentageCalculate(kline.trends.up.bottom, 'minus', 1) && kline.candlestick.open < kline.trends.up.top && kline.candlestick.open > kline.trends.up.bottom;
    
    let prevKlineOverflowDownChannelDown = prevKline.candlestick.close < prevKline.trends.down.bottom && prevKline.candlestick.close > extendPercentageCalculate(prevKline.trends.down.bottom, 'minus', 1) && prevKline.candlestick.open < prevKline.trends.down.top && prevKline.candlestick.open > prevKline.trends.down.bottom;
    let twoPrevKlineOverflowDownChannelDown = twoPrevKline.candlestick.close < twoPrevKline.trends.down.bottom && twoPrevKline.candlestick.close > extendPercentageCalculate(twoPrevKline.trends.down.bottom, 'minus', 1) &&  twoPrevKline.candlestick.open < twoPrevKline.trends.down.top && twoPrevKline.candlestick.open > twoPrevKline.trends.down.bottom;

    let prevKlineOverflowDownChannelUp = prevKline.candlestick.close < prevKline.trends.up.bottom && prevKline.candlestick.close > extendPercentageCalculate(prevKline.trends.up.bottom, 'minus', 1) && prevKline.candlestick.open < prevKline.trends.up.top && prevKline.candlestick.open > prevKline.trends.up.bottom;
    let twoPrevKlineOverflowDownChannelUp = twoPrevKline.candlestick.close < twoPrevKline.trends.up.bottom && twoPrevKline.candlestick.close > extendPercentageCalculate(twoPrevKline.trends.up.bottom, 'minus', 1) && twoPrevKline.candlestick.open < twoPrevKline.trends.up.top && twoPrevKline.candlestick.open > twoPrevKline.trends.up.bottom;

    let klineOutsideDownChannelDown = kline.candlestick.close < kline.trends.down.bottom && kline.candlestick.open < kline.trends.down.bottom && kline.candlestick.change > 1 && kline.candlestick.change < 3;
    let klineOutsideDownChannelUp = kline.candlestick.close < kline.trends.up.bottom && kline.candlestick.open < kline.trends.up.bottom && kline.candlestick.change > 1 && kline.candlestick.change < 3;

    /*
    if(klineOverflowDownChannelDown && isPrevKlineInsideDownChannel && isTwoPrevKlineInsideDownChannel) {
        shortPoint = 100;
    }/*
    else if(prevKlineOverflowDownChannelDown && klineOutsideDownChannelDown && prevKline.candlestick.change < 5) {
        shortPoint = 90;
    }
    else if(twoPrevKlineOverflowDownChannelDown && klineOutsideDownChannelDown && twoPrevKline.candlestick.change < 5) {
        shortPoint = 80;
    }*/
    /*
    if(klineOverflowDownChannelUp && isPrevKlineInsideUpChannel && isTwoPrevKlineInsideUpChannel) {
        shortPoint = 100;
    }/*
    else if(prevKlineOverflowDownChannelUp && klineOutsideDownChannelUp && prevKline.candlestick.change < 5) {
        shortPoint = 90;
    }
    else if(twoPrevKlineOverflowDownChannelUp && klineOutsideDownChannelUp && twoPrevKline.candlestick.change < 5) {
        shortPoint = 80;
    }*/



    /*****/
    /*
    let prevKlineOverflowUpChannelDownX = prevKline.candlestick.close > prevKline.trends.down.top && prevKline.candlestick.open < prevKline.trends.down.top && prevKline.candlestick.open > prevKline.trends.down.bottom;
    let klineOutsideUpChannelDownX = kline.candlestick.close > kline.trends.down.top && kline.candlestick.open > kline.trends.down.top && kline.candlestick.color === 'green' && kline.candlestick.change < 5;

    if(isTwoPrevKlineInsideDownChannel && prevKlineOverflowUpChannelDownX && klineOutsideUpChannelDownX) {
        longPoint = 100;
    }

    let prevKlineOverflowUpChannelUpX = prevKline.candlestick.close > prevKline.trends.up.top && prevKline.candlestick.open < prevKline.trends.up.top && prevKline.candlestick.open > prevKline.trends.up.bottom;
    let klineOutsideUpChannelUpX = kline.candlestick.close > kline.trends.up.top && kline.candlestick.open > kline.trends.up.top && kline.candlestick.color === 'green' && kline.candlestick.change < 5;

    if(prevKlineOverflowUpChannelUpX && klineOutsideUpChannelUpX && isTwoPrevKlineInsideUpChannel) {
        longPoint = 100;
    }

    let prevKlineOverflowDownChannelDownX = prevKline.candlestick.close < prevKline.trends.down.bottom && prevKline.candlestick.open < prevKline.trends.down.top && prevKline.candlestick.open > prevKline.trends.down.bottom;
    let klineOutsideDownChannelDownX = kline.candlestick.close < kline.trends.down.bottom && kline.candlestick.open < kline.trends.down.bottom && kline.candlestick.color === 'red' && kline.candlestick.change > -5;
    
    if(prevKlineOverflowDownChannelDownX && klineOutsideDownChannelDownX && isTwoPrevKlineInsideDownChannel) {
        shortPoint = 100;
    }

    let prevKlineOverflowDownChannelUpX = prevKline.candlestick.close < prevKline.trends.up.bottom && prevKline.candlestick.open < prevKline.trends.up.top && prevKline.candlestick.open > prevKline.trends.up.bottom;
    let klineOutsideDownChannelUpX = kline.candlestick.close < kline.trends.up.bottom && kline.candlestick.open < kline.trends.up.bottom && kline.candlestick.color === 'red' && kline.candlestick.change > -5;

    if(prevKlineOverflowDownChannelUpX && klineOutsideDownChannelUpX && isTwoPrevKlineInsideUpChannel) {
        shortPoint = 100;
    }
    */
/*
    if(prevKline.candlestick.color === 'red' &&
       prevKline.candlestick.low < prevKline.trends.down.bottom &&
       prevKline.candlestick.open > prevKline.trends.down.bottom &&
       prevKline.candlestick.close > prevKline.trends.down.bottom &&
       prevKline.candlestick.open < prevKline.trends.down.top &&
       kline.candlestick.color === 'green' &&
       kline.candlestick.low < kline.trends.down.bottom &&
       kline.candlestick.close > kline.trends.down.bottom &&
       kline.candlestick.open > kline.trends.down.bottom) {
        longPoint = 100;
    }

    if(prevKline.candlestick.color === 'red' &&
       prevKline.candlestick.low < prevKline.trends.up.bottom &&
       prevKline.candlestick.open > prevKline.trends.up.bottom &&
       prevKline.candlestick.close > prevKline.trends.up.bottom &&
       prevKline.candlestick.open < prevKline.trends.up.top &&
       kline.candlestick.color === 'green' &&
       kline.candlestick.low < kline.trends.up.bottom &&
       kline.candlestick.close > kline.trends.up.bottom &&
       kline.candlestick.open > kline.trends.up.bottom) {
        longPoint = 100;
    }
*/

    /* --- Kanal alt bandından iğne ile taşıp, geri girişlerin kontrolü --- */ 
    if(kline.candlestick.color === 'green' &&
       kline.candlestick.close > kline.trends.down.bottom &&
       kline.candlestick.low < kline.trends.down.bottom &&
       prev1Kline.candlestick.color === 'red' &&
       //kline.candlestick.change < 5 &&
       //kline.trends.down.width > 8 &&
       prev1Kline.candlestick.open > prev1Kline.trends.down.bottom &&
       prev1Kline.candlestick.low < prev1Kline.trends.down.bottom &&
       kline.candlestick.close > prev1Kline.candlestick.open &&
       isInsideChannel(prev2Kline, 'down') &&
       prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
       isInsideChannel(prev3Kline, 'down') &&
       prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
       isInsideChannel(prev4Kline, 'down') &&
       prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
       isInsideChannel(prev5Kline, 'down')
    ) {
        longPoint = 100;
        type = "DB";
    }
    else if(kline.candlestick.color === 'green' &&
      kline.candlestick.close > kline.trends.up.bottom &&
      kline.candlestick.low < kline.trends.up.bottom &&
      prev1Kline.candlestick.color === 'red' &&
      //kline.candlestick.change < 5 &&
      //kline.trends.up.width > 8 &&
      prev1Kline.candlestick.open > prev1Kline.trends.up.bottom &&
      prev1Kline.candlestick.low < prev1Kline.trends.up.bottom &&
      kline.candlestick.close > prev1Kline.candlestick.open &&
      isInsideChannel(prev2Kline, 'up') &&
      prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
      isInsideChannel(prev3Kline, 'up') &&
      prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
      isInsideChannel(prev4Kline, 'up') &&
      prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
      isInsideChannel(prev5Kline, 'up')
    ) {
        longPoint = 100;
        type = 'UB';
    }
    /*******************************************************************************/
    /* --- Kanal orta bandından iğne ile taşıp, geri girişlerin kontrolü --- */ 
    else if(kline.candlestick.color === 'green' &&
       kline.candlestick.close > kline.trends.down.mid &&
       kline.candlestick.low < kline.trends.down.mid &&
       prev1Kline.candlestick.color === 'red' &&
       //kline.candlestick.change < 5 &&
       //kline.trends.down.width > 8 &&
       prev1Kline.candlestick.open > prev1Kline.trends.down.mid &&
       prev1Kline.candlestick.low < prev1Kline.trends.down.mid &&
       kline.candlestick.close > prev1Kline.candlestick.open &&
       isHalfTopInsideChannel(prev2Kline, 'down') &&
       prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
       isHalfTopInsideChannel(prev3Kline, 'down') &&
       prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
       isHalfTopInsideChannel(prev4Kline, 'down') &&
       prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
       isHalfTopInsideChannel(prev5Kline, 'down')
    ) {
        longPoint = 100;
        type = 'DM';
    }
    else if(kline.candlestick.color === 'green' &&
      kline.candlestick.close > kline.trends.up.mid &&
      kline.candlestick.low < kline.trends.up.mid &&
      prev1Kline.candlestick.color === 'red' &&
      //kline.candlestick.change < 5 &&
      //kline.trends.up.width > 8 &&
      prev1Kline.candlestick.open > prev1Kline.trends.up.mid &&
      prev1Kline.candlestick.low < prev1Kline.trends.up.mid &&
      kline.candlestick.close > prev1Kline.candlestick.open &&
      isHalfTopInsideChannel(prev2Kline, 'up') &&
      prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
      isHalfTopInsideChannel(prev3Kline, 'up') &&
      prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
      isHalfTopInsideChannel(prev4Kline, 'up') &&
      prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
      isHalfTopInsideChannel(prev5Kline, 'up')
    ) {
        longPoint = 100;
        type = 'UM';
    }
    /*******************************************************************************/
    /* --- önceki 4 mumm içeride, son mum yeşil ve kanalın üst çizgisi dışında kapanış yapmış. --- */
    else if(isInsideChannel(prev1Kline, 'up') &&
       isInsideChannel(prev2Kline, 'up') &&
       isInsideChannel(prev3Kline, 'up') &&
       isInsideChannel(prev4Kline, 'up') &&
       //kline.trends.up.width > 5 &&
       //kline.candlestick.change <= 25 &&
       //kline.candlestick.change > 1 &&
       //kline.candlestick.open > kline.trends.up.mid &&
       isOverflow(kline, 'up')) {
        longPoint = 100;
        type = 'UO';
    }
    else if(isInsideChannel(prev1Kline, 'down') &&
       isInsideChannel(prev2Kline, 'down') &&
       isInsideChannel(prev3Kline, 'down') &&
       isInsideChannel(prev4Kline, 'down') &&
       //kline.trends.down.width > 5 &&
       //kline.candlestick.change <= 25 &&
       //kline.candlestick.change > 1 &&
       //kline.candlestick.open > kline.trends.down.mid &&
       isOverflow(kline, 'down')) {
        longPoint = 100;
        type = 'DO';
    } 
    /*******************************************************************************/
    /* --- Kanal üst bandından iğne ile girip, geri çıkış kontrolü --- */ 
    else if(kline.candlestick.color === 'green' &&
       kline.candlestick.close > kline.trends.down.top &&
       kline.candlestick.low < kline.trends.down.top &&
       prev1Kline.candlestick.color === 'red' &&
       kline.candlestick.change < 3 &&
       prev1Kline.candlestick.open > prev1Kline.trends.down.top &&
       prev1Kline.candlestick.low < prev1Kline.trends.down.top &&
       kline.candlestick.close > prev1Kline.candlestick.open &&
       isOutsideChannel(prev2Kline, 'down') &&
       prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
       isOutsideChannel(prev3Kline, 'down') &&
       prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
       isOutsideChannel(prev4Kline, 'down')
    ) {
        longPoint = 100;
        type = 'DT';
    }
    else if(kline.candlestick.color === 'green' &&
      kline.candlestick.close > kline.trends.up.top &&
      kline.candlestick.low < kline.trends.up.top &&
      prev1Kline.candlestick.color === 'red' &&
      kline.candlestick.change < 3 &&
      prev1Kline.candlestick.open > prev1Kline.trends.up.top &&
      prev1Kline.candlestick.low < prev1Kline.trends.up.top &&
      kline.candlestick.close > prev1Kline.candlestick.open &&
      isOutsideChannel(prev2Kline, 'up') &&
      prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
      isOutsideChannel(prev3Kline, 'up') &&
      prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
      isOutsideChannel(prev4Kline, 'up')
    ) {
        longPoint = 100;
        type = 'UT';
    } 
    /*******************************************************************************/


/*
    if(kline.candlestick.color === 'green' &&
    kline.candlestick.close > kline.trends.down.top &&
    kline.candlestick.change < 3 &&
    prev1Kline.candlestick.color === 'green' &&
    prev1Kline.candlestick.close > prev1Kline.trends.down.top &&
    prev1Kline.candlestick.low < prev1Kline.trends.down.top &&
    prev1Kline.candlestick.change < 3 &&
    prev2Kline.candlestick.color === 'red' &&
    prev2Kline.candlestick.open > prev2Kline.trends.down.top &&
    prev2Kline.candlestick.low < prev2Kline.trends.down.top &&
    isOutsideChannel(prev3Kline, 'down') &&
    prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
    isOutsideChannel(prev4Kline, 'down') &&
    prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
    isOutsideChannel(prev5Kline, 'down')
    ) {
        longPoint = 100;
        type = 'DT';
    }
    else if(kline.candlestick.color === 'green' &&
    kline.candlestick.close > kline.trends.up.top &&
    kline.candlestick.change < 3 &&
    prev1Kline.candlestick.color === 'green' &&
    prev1Kline.candlestick.close > prev1Kline.trends.up.top &&
    prev1Kline.candlestick.low < prev1Kline.trends.up.top &&
    prev1Kline.candlestick.change < 3 &&
    prev2Kline.candlestick.color === 'red' &&
    prev2Kline.candlestick.open > prev2Kline.trends.up.top &&
    prev2Kline.candlestick.low < prev2Kline.trends.up.top &&
    isOutsideChannel(prev3Kline, 'up') &&
    prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
    isOutsideChannel(prev4Kline, 'up') &&
    prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
    isOutsideChannel(prev5Kline, 'up')
    ) {
        longPoint = 100;
        type = 'UT';
    } 
    */
    return {longPoint, shortPoint, type}
}

const calculateTrendLinesPointsTest = (kline, prevKline, twoPrevKline, prevKlines, currentBTC, analysisTypes) => {
    let longPoint = 0;
    let shortPoint = 0;
    let type = '';

    let prev1Kline = [...prevKlines][prevKlines.length - 1];
    let prev2Kline = [...prevKlines][prevKlines.length - 2];
    let prev3Kline = [...prevKlines][prevKlines.length - 3];
    let prev4Kline = [...prevKlines][prevKlines.length - 4];
    let prev5Kline = [...prevKlines][prevKlines.length - 5];
    let prev6Kline = [...prevKlines][prevKlines.length - 6];
    let prev7Kline = [...prevKlines][prevKlines.length - 7];
    let prev8Kline = [...prevKlines][prevKlines.length - 8];
    let prev9Kline = [...prevKlines][prevKlines.length - 9];
    let prev10Kline = [...prevKlines][prevKlines.length - 10]; 


    //let currentBTCTopWick = calculateBetweenPercentage(currentBTC.candlestick.high, currentBTC.candlestick.close);
    //console.log('current btc', currentBTC.candlestick.change, currentBTCTopWick);

    /************************************************************/
    /************************************************************/
    /************************************************************/
    // Bu BTC olayının biraz üstüne bi gidelim bakalım buradan makul bir kısıtlama çıkabilir.
    if(true/*currentBTC.candlestick.change < 1*//* && currentBTCTopWick < 1*/) {
/* --- Artan Kanal alt bandından iğne ile taşıp, geri girişlerin kontrolü --- */ 
    if(analysisTypes.upBottom &&
        kline.candlestick.color === 'green' &&
        kline.candlestick.close > kline.trends.up.bottom &&
        //kline.candlestick.low < kline.trends.up.bottom &&
        prev1Kline.candlestick.color === 'red' &&
        prev1Kline.candlestick.open > prev1Kline.trends.up.bottom &&
        prev1Kline.candlestick.low < prev1Kline.trends.up.bottom &&
        kline.candlestick.close > prev1Kline.candlestick.open &&
        isInsideChannel(prev2Kline, 'up') &&
        //prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
        isInsideChannel(prev3Kline, 'up') &&
        //prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
        isInsideChannel(prev4Kline, 'up') &&
        //prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
        isInsideChannel(prev5Kline, 'up')
    ) {
    longPoint = 100;
    type = 'UB';
    }
// --- Düşen Kanal alt bandından iğne ile taşıp, geri girişlerin kontrolü --- 
    else if(analysisTypes.downBottom &&
            kline.candlestick.color === 'green' &&
            kline.candlestick.close > kline.trends.down.bottom &&
            //kline.candlestick.low < kline.trends.down.bottom &&
            prev1Kline.candlestick.color === 'red' &&
            prev1Kline.candlestick.open > prev1Kline.trends.down.bottom &&
            prev1Kline.candlestick.low < prev1Kline.trends.down.bottom &&
            kline.candlestick.close > prev1Kline.candlestick.open &&
            isInsideChannel(prev2Kline, 'down') &&
            //prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
            isInsideChannel(prev3Kline, 'down') &&
            //prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
            isInsideChannel(prev4Kline, 'down') &&
            //prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
            isInsideChannel(prev5Kline, 'down')
    ) {
    longPoint = 100;
    type = "DB";
    }
// --- Artan Kanal orta bandından iğne ile taşıp, geri girişlerin kontrolü --- 
    else if(analysisTypes.upMid &&
            kline.candlestick.color === 'green' &&
            kline.candlestick.close > kline.trends.up.mid &&
            //kline.candlestick.low < kline.trends.up.mid &&
            prev1Kline.candlestick.color === 'red' &&
            prev1Kline.candlestick.open > prev1Kline.trends.up.mid &&
            prev1Kline.candlestick.low < prev1Kline.trends.up.mid &&
            kline.candlestick.close > prev1Kline.candlestick.open &&
            isHalfTopInsideChannel(prev2Kline, 'up') &&
            prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
            isHalfTopInsideChannel(prev3Kline, 'up') &&
            prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
            isHalfTopInsideChannel(prev4Kline, 'up') &&
            prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
            isHalfTopInsideChannel(prev5Kline, 'up')
    ) {
    longPoint = 70;
    type = 'UM';
    }
// --- Düşen Kanal orta bandından iğne ile taşıp, geri girişlerin kontrolü --- 
    else if(analysisTypes.downMid &&
            kline.candlestick.color === 'green' &&
            kline.candlestick.close > kline.trends.down.mid &&
            //kline.candlestick.low < kline.trends.down.mid &&
            prev1Kline.candlestick.color === 'red' &&
            prev1Kline.candlestick.open > prev1Kline.trends.down.mid &&
            prev1Kline.candlestick.low < prev1Kline.trends.down.mid &&
            kline.candlestick.close > prev1Kline.candlestick.open &&
            isHalfTopInsideChannel(prev2Kline, 'down') &&
            prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
            isHalfTopInsideChannel(prev3Kline, 'down') &&
            prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
            isHalfTopInsideChannel(prev4Kline, 'down') &&
            prev4Kline.candlestick.close > prev3Kline.candlestick.close &&
            isHalfTopInsideChannel(prev5Kline, 'down')
    ) {
    longPoint = 100;
    type = 'DM';
    }
// --- iki kanalı birden aynı anda kıran -- 
    else if(analysisTypes.fullOverflow &&
            isOverflow(kline, 'down') && // düşen kanaldan taşma oluyor.
            isOverflow(kline, 'up') && // artan kanaldan taşma oluyor.
            kline.candlestick.open < kline.trends.down.top && // açılış, düşen kanal üst bandından küçük;
            kline.candlestick.open < kline.trends.up.top && // açılış, artan kanal üst bandından küçük;
            kline.trends.up.width < 25 && // artan kanal genişliği %25'ten küçük.
            kline.trends.down.width < 25 // düşen kanal genişliği %25'ten küçük.
    ) {
    longPoint = 80;
    type = 'FO+';  
    }
// --- artan kanalı kıran, düşen kanal içinde kapanış yapmış olan --- 
    else if(analysisTypes.upOverflowPlus &&
            isOverflow(kline, 'up') && // artan kanaldan taşma oluyor;
            kline.candlestick.change > 1 && // mum büyüklüğü %1'den büyük;
            isInsideChannel(prev1Kline, 'up') && // bir önceki mum, artan kanalın içerisinde;
            kline.candlestick.close < kline.trends.down.top && // güncel kapanış, düşen kanalın üst bandından küçük,
            kline.candlestick.close > kline.trends.down.bottom && // güncel kapanış, düşen kanalın alt bandından büyük,
            kline.candlestick.high < kline.trends.down.top && // güncel high, düşen kanalın üst bandından küçük,
            kline.trends.down.width < 25 // düşen kanal genişliği %25'ten küçük.
            //kline.candlestick.open < kline.trends.down.bottom
    ) {
    longPoint = 80;
    type = 'UO+';   
    }
// --- düşen kanalı kıran, artan kanalın içinde kapanış yapmış olan --- 
    else if(analysisTypes.downOverflowPlus &&
            isOverflow(kline, 'down') && // düşen kanaldan taşma oluyor.
            kline.candlestick.change > 1 && // mum büyüklüğü %1'den büyük;
            isInsideChannel(prev1Kline, 'down') && // bir önceki mum, düşen kanalın içerisinde;
            kline.candlestick.close < kline.trends.up.top && // güncel kapanış, artan kanalın üst bandından küçük,
            kline.candlestick.close > kline.trends.up.bottom && // güncel kapanış, artan kanalın alt bandından büyük,
            kline.candlestick.high < kline.trends.up.top && // güncel high, artan kanalın üst bandından küçük,
            kline.trends.up.width < 25 // artan kanal genişliği %25'ten küçük.
            //kline.candlestick.open < kline.trends.up.bottom
    ) {
    longPoint = 90;
    type = 'DO+';
    }
// --- önceki 1 mumm içeride, son mum yeşil ve artan kanalın üst çizgisi dışında kapanış yapmış. ---
    else if(analysisTypes.upOverflow &&
            isInsideChannel(prev1Kline, 'up') &&
            //isInsideChannel(prev2Kline, 'up') &&
            //isInsideChannel(prev3Kline, 'up') &&
            //isInsideChannel(prev4Kline, 'up') &&
            //kline.trends.up.width > 5 &&
            //kline.candlestick.change <= 25 &&
            kline.candlestick.change > 0.5 &&
            //kline.candlestick.open > kline.trends.up.mid &&
            isOverflow(kline, 'up')
            //kline.candlestick.low <= kline.trends.up.top
    ) {
    longPoint = 80;
    type = 'UO';
    }
// --- önceki 1 mumm içeride, son mum yeşil ve düşen kanalın üst çizgisi dışında kapanış yapmış. --- 
    else if(analysisTypes.downOverflow &&
            isInsideChannel(prev1Kline, 'down') &&
            //isInsideChannel(prev2Kline, 'down') &&
            //isInsideChannel(prev3Kline, 'down') &&
            //isInsideChannel(prev4Kline, 'down') &&
            //kline.trends.down.width > 5 &&
            //kline.candlestick.change <= 25 &&
            kline.candlestick.change > 0.5 &&
            //kline.candlestick.open > kline.trends.down.mid &&
            isOverflow(kline, 'down')
            //kline.candlestick.low <= kline.trends.down.top
    ) {
    longPoint = 90;
    type = 'DO';
    } 
// --- Artan Kanal üst bandından iğne ile girip, geri çıkış kontrolü ---
    else if(analysisTypes.upTop &&
            kline.candlestick.color === 'green' &&
            kline.candlestick.close > kline.trends.up.top &&
            //kline.candlestick.low < kline.trends.up.top &&
            prev1Kline.candlestick.color === 'red' &&
            //kline.candlestick.change < 3 &&
            prev1Kline.candlestick.open > prev1Kline.trends.up.top &&
            prev1Kline.candlestick.low < prev1Kline.trends.up.top &&
            kline.candlestick.close > prev1Kline.candlestick.open &&
            isOutsideChannel(prev2Kline, 'up') &&
            prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
            isOutsideChannel(prev3Kline, 'up') &&
            prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
            isOutsideChannel(prev4Kline, 'up')
    ) {
    longPoint = 70;
    type = 'UT';
    }
// --- Düşen Kanal üst bandından iğne ile girip, geri çıkış kontrolü ---
    else if(analysisTypes.downTop &&
            kline.candlestick.color === 'green' &&
            kline.candlestick.close > kline.trends.down.top &&
            //kline.candlestick.low < kline.trends.down.top &&
            prev1Kline.candlestick.color === 'red' &&
            //kline.candlestick.change < 3 &&
            prev1Kline.candlestick.open > prev1Kline.trends.down.top &&
            prev1Kline.candlestick.low < prev1Kline.trends.down.top &&
            kline.candlestick.close > prev1Kline.candlestick.open &&
            isOutsideChannel(prev2Kline, 'down') &&
            prev2Kline.candlestick.close > prev1Kline.candlestick.close &&
            isOutsideChannel(prev3Kline, 'down') &&
            prev3Kline.candlestick.close > prev2Kline.candlestick.close &&
            isOutsideChannel(prev4Kline, 'down')
    ) {
    longPoint = 80;
    type = 'DT';
    }
}
    

    return {longPoint, shortPoint, type}
}


// mumun açılış ve kapanışları kanalın içindedir.
const isInsideChannel = (kline, channelType) => {
    let isInside = false;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;
    if (channelType === 'up') {
        isInside = candle.close < up.top && candle.open < up.top && candle.close > up.bottom && candle.open > up.bottom;
    }
    else {
        isInside = candle.close < down.top && candle.open < down.top && candle.close > down.bottom && candle.open > down.bottom;
    }

    return isInside;
}

// mum, iğneleri ile beraber kanalın komple içindedir.
const isFullInsideChannel = (kline, channelType) => {
    let isInside = false;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;
    if (channelType === 'up') {
        isInside = candle.low < up.top && candle.high < up.top && candle.low > up.bottom && candle.high > up.bottom;
    }
    else {
        isInside = candle.low < down.top && candle.high < down.top && candle.low > down.bottom && candle.high > down.bottom;
    }

    return isInside;
}

// mumun açılış ve kapanışları kanalın üst ve orta bandı arasındadır.
const isHalfTopInsideChannel = (kline, channelType) => {
    let isInside = false;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;
    if (channelType === 'up') {
        isInside = candle.close < up.top && candle.open < up.top && candle.close > up.mid && candle.open > up.mid;
    }
    else {
        isInside = candle.close < down.top && candle.open < down.top && candle.close > down.mid && candle.open > down.mid;
    }

    return isInside;
}

// mum, iğneleri ile beraber kanalın üst ve orta bandı arasındadır.
const isHalfTopFullInsideChannel = (kline, channelType) => {
    let isInside = false;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;
    if (channelType === 'up') {
        isInside = candle.low < up.top && candle.high < up.top && candle.low > up.mid && candle.high > up.mid;
    }
    else {
        isInside = candle.low < down.top && candle.high < down.top && candle.low > down.mid && candle.high > down.mid;
    }

    return isInside;
}

// mumun açılış ve kapanışları kanalın orta ve alt bandı arasındadır.
const isHalfBottomInsideChannel = (kline, channelType) => {
    let isInside = false;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;
    if (channelType === 'up') {
        isInside = candle.close < up.mid && candle.open < up.mid && candle.close > up.bottom && candle.open > up.bottom;
    }
    else {
        isInside = candle.close < down.mid && candle.open < down.mid && candle.close > down.bottom && candle.open > down.bottom;
    }

    return isInside;
}

// mum, iğneleri ile beraber kanalın orta ve alt bandı arasındadır.
const isHalfBottomFullInsideChannel = (kline, channelType) => {
    let isInside = false;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;
    if (channelType === 'up') {
        isInside = candle.low < up.mid && candle.high < up.mid && candle.low > up.bottom && candle.high > up.bottom;
    }
    else {
        isInside = candle.low < down.mid && candle.high < down.mid && candle.low > down.bottom && candle.high > down.bottom;
    }

    return isInside;
}

const isOutsideChannel = (kline, channelType) => {
    let isOutside = false;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;
    if (channelType === 'up') {
        isOutside = candle.close > up.top && candle.open > up.top;
    }
    else {
        isOutside = candle.close > down.top && candle.open > down.top;
    }

    return isOutside;
}

const isFullOutsideChannel = (kline, channelType) => {
    let isOutside = false;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;
    if (channelType === 'up') {
        isOutside = candle.high > up.top && candle.low > up.top;
    }
    else {
        isOutside = candle.high > down.top && candle.low > down.top;
    }

    return isOutside;
}

const isOverflow = (kline, channelType) => {
    let isOverflow = false;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;

    if (channelType === 'up') {
        isOverflow = candle.close > up.top &&  // kapanış, kanalın üstünden en az %1 daha büyükse;
                     candle.open > up.bottom && // açılış, kanalın altından büyükse;
                     // open'ın normalde üst banttan daha küçük olması gerekir. tam kanalın içinde olması için.
                     candle.color === 'green'; // mum yeşilse;
    }
    else {
        isOverflow = candle.close > down.top && 
                     candle.open > down.bottom && 
                     candle.color === 'green';
    }

    return isOverflow;
}

const calculateCandlestickFormationPoints = () => {
}

const calculateSupplyDemandArea = (kline, prevKline, supDemAreas, processDirection) => {
    // Burada daha önceden belirlenmiş supply demand alanlarına temas olup olmadığı kontrol edilecek her mum için.

    //console.log('CALCULATE SUPPLY DEMAND', kline, prevKline, supDemAreas, supDemAreas.length);
    let longPoint = 0;
    let shortPoint = 0;
    let type = '';

    if (supDemAreas.length > 0) {
        // son 5 bölgeyi dikkate alıyor. buna gerek olmayacak aktif pasif durumu ekledik.
        let filteredSupDemAreas = supDemAreas.length > 5 ? [...supDemAreas].splice(supDemAreas.length - 5, 5) : supDemAreas;
        [...supDemAreas].reverse().find(sd => {
            if (processDirection.long && sd.type === "demand" && kline.candlestick.low <= sd.high && kline.candlestick.close > sd.low && prevKline.candlestick.low > sd.high && kline.candlestick.closeTime > sd.activationDate && kline.candlestick.closeTime < sd.expiredDate) {
                longPoint = 100;
                type = 'D';
                return;
            }

            if (processDirection.short && sd.type === "supply" && kline.candlestick.high >= sd.low && kline.candlestick.close < sd.high && prevKline.candlestick.high < sd.low && kline.candlestick.closeTime > sd.activationDate && kline.candlestick.closeTime < sd.expiredDate) {
                shortPoint = 100;
                type = 'S';
                return;
            }
        });
    }

    return {longPoint, shortPoint, type}
}


// OLD MODELS
const calculateLongBollRsi = (kline, prevKline, twoPrevKline) => {
    let longPoint = 0;
    let shortPoint = 0;

    // OL-br: Hem Rsi hem boll alt banddan taşıp tekrar yeşil mumla içeri girilmesi ardından ikinci bir yeşil mum beklenir.
    const openLongBollRsi = ((twoPrevKline.candlestick.close < twoPrevKline.indicators.bollinger.bbLow || twoPrevKline.candlestick.open < twoPrevKline.indicators.bollinger.lower) && 
                             twoPrevKline.indicators.rsi < 30) &&
                            (prevKline.candlestick.color === 'green' && prevKline.candlestick.close > prevKline.indicators.bollinger.lower && prevKline.indicators.rsi > 30) &&
                            (kline.candlestick.color === 'green' && kline.candlestick.close > kline.indicators.bollinger.lower && kline.candlestick.open > kline.indicators.bollinger.lower && kline.indicators.rsi > 30);
    
    if(openLongBollRsi) {
        longPoint = 100;
    }
    return {longPoint, shortPoint}
}

const calculateLongBollBandwidth = (kline, prevKline, twoPrevKline, bollBandwidthPercentage, twoPrevBollBandwidthPercentage) => {
    let longPoint = 0;
    let shortPoint = 0;

    // OL-bb: Son 3 mum yeşil ilk mum bant genişliği %3 altında, son mum bant genişliği %3 üzerinde olmalı. 
    const openLongBollBands = twoPrevKline.candlestick.color === 'green' &&
                              prevKline.candlestick.color === 'green' &&
                              kline.candlestick.color === 'green' &&
                              kline.candlestick.close > kline.indicators.bollinger.middle &&
                              kline.candlestick.change < 4 &&
                              ((twoPrevBollBandwidthPercentage < 3 && bollBandwidthPercentage > 3) ||
                              (twoPrevBollBandwidthPercentage < 4 && bollBandwidthPercentage > 4));

    if(openLongBollBands) {
        longPoint = 100;
    }
    return {longPoint, shortPoint}
}

const calculateLongPercent3 = (kline, prevKline) => {
    let longPoint = 0;
    let shortPoint = 0;
    // OL-p3: son kapanan mum yeşil ve %2.8, %3 boyutlarındadır. 
    let openLongPercent3 = kline.candlestick.change > 2.8;
    if(openLongPercent3) {
        longPoint = 100;
    }
    return {longPoint, shortPoint}
}

const calculateLongExplosion = (kline, prevKline, prevKlines, rsiOne, rsiTwo) => {
    let longPoint = 0;
    let shortPoint = 0;
    const openLongExplosion = prevKlines.klinesPercentage.length === 5 &&
                              prevKlines.parallelEq === 4 &&
                              kline.indicators.ema20 > kline.indicators.ema50 &&
                              kline.indicators.ema20 > kline.indicators.ema100 &&
                              kline.indicators.ema20 > kline.indicators.ema200 &&
                              kline.candlestick.close > kline.indicators.ema20 &&
                              kline.candlestick.open > kline.indicators.ema50 &&
                              kline.candlestick.color === 'green' &&
                              kline.candlestick.close > kline.indicators.bollinger.middle &&
                              kline.indicators.bollinger.upper > prevKline.indicators.bollinger.upper &&
                              kline.indicators.rsi > rsiOne && kline.indicators.rsi < rsiTwo &&
                              kline.candlestick.change > 0.9 && kline.candlestick.change < 1.5;

    if(openLongExplosion) {
        longPoint = 100;
    }
    return {longPoint, shortPoint}
}

// TODO: buradaki değerlerle biraz oyna bakalım.
const calculateLongExplosionLoose = (kline, prevKline, prevKlines) => {
    let longPoint = 0;
    let shortPoint = 0;
    //console.log('calculateLongExplosionLoose', prevKlines.prevs, prevKlines.maxTopPrice, kline.candlestick.close);
    const openLongExplosionLoose = prevKlines.klinesPercentage.length === 5 &&
                                   prevKlines.maxTopPrice < kline.candlestick.close &&
                                   kline.candlestick.close > kline.indicators.ema20 &&
                                   kline.candlestick.color === 'green' &&
                                   kline.candlestick.close > kline.indicators.bollinger.middle &&
                                   kline.indicators.bollinger.upper > prevKline.indicators.bollinger.upper &&
                                   kline.indicators.rsi > 55 &&
                                   kline.candlestick.change > 0.9;
    if(openLongExplosionLoose) {
        longPoint = 100;
    }
    return {longPoint, shortPoint}
}

const calculateLongExplosionTight = (kline, prevKline, prevKlines, rsiOne, rsiTwo) => {
    let longPoint = 0;
    let shortPoint = 0;
    //console.log('calculateLongExplosionTight', kline, prevKline, prevKlinesPercentage, greenPrevKlines, prevKlinesAverageWidth);
    const openLongExplosionTight = prevKlines.klinesPercentage.length === 5 &&
                              prevKlines.greenKlinesCount < 5 &&
                              prevKlines.klinesAverageWidth < 1 &&
                              //parallelEq === 4 &&
                              kline.indicators.ema20 > kline.indicators.ema50 &&
                              kline.indicators.ema20 > kline.indicators.ema100 &&
                              kline.indicators.ema20 > kline.indicators.ema200 &&
                              kline.candlestick.close > kline.indicators.ema20 &&
                              kline.candlestick.open > kline.indicators.ema50 &&
                              kline.candlestick.color === 'green' &&
                              kline.candlestick.close > kline.indicators.bollinger.middle &&
                              kline.indicators.bollinger.upper > prevKline.indicators.bollinger.upper &&
                              kline.indicators.rsi > rsiOne && kline.indicators.rsi < rsiTwo &&
                              kline.candlestick.change > 1.1 && kline.candlestick.change < 1.5;

    if(openLongExplosionTight) {
        longPoint = 100;
    }
    return {longPoint, shortPoint}
}

const calculateLongZombieSpot = (kline, prevKline, prevKlines, rsiOne, rsiTwo) => {
    let longPoint = 0;
    let shortPoint = 0;
    //console.log('calculateLongExplosionTight', kline, prevKline, prevKlinesPercentage, greenPrevKlines, prevKlinesAverageWidth);
    const openLongZombieSpot = prevKlines.klinesPercentage.length === spotPrevKlines &&
                               prevKlines.parallelEq === spotPrevKlines - 1;
    
    if(openLongZombieSpot) {
        longPoint = 100;
    }
    return {longPoint, shortPoint}
}

const calculateLongDepthHill = (kline, prevKline, twoPrevKline, supports) => {
    let longPoint = 0;

    let activeLines = supports.filter(l => l.price >= prevKline.candlestick.open && l.price < prevKline.candlestick.close && l.strength > 1);
    let linePrices = activeLines.map(x => x.price);
    let smallestLine = activeLines.length > 0 ? Math.min(...linePrices) : 0;

    const oldh1 = twoPrevKline.candlestick.color === 'red' &&
                  prevKline.candlestick.color === 'green' &&
                  kline.candlestick.color === 'green' &&
                  smallestLine > 0 &&
                  hasActiveLine(smallestLine, twoPrevKline.candlestick.open, twoPrevKline.candlestick.close);// &&
                  //prevKline.candlestick.close < prevKline.indicators.ema50 &&
                  //prevKline.candlestick.close < prevKline.indicators.ema21;

    let condition = false;
    let type = '';

    if(oldh1) {
        condition = true;
        type = 1;
        longPoint = 100;
    }
    else {
        condition = false;
        type = "";
    }

    return { condition: condition, type: type, supportLine: smallestLine, longPoint };
}

const calculateShortDepthHill = (kline, prevKline, twoPrevKline, supports) => {
    let shortPoint = 0;
    let activeLines = supports.filter(l => l.price > prevKline.candlestick.close && l.price <= prevKline.candlestick.open && l.strength > 1);
    let linePrices = activeLines.map(x => x.price);
    let smallestLine = activeLines.length > 0 ? Math.min(...linePrices) : 0;

    const osdh1 = twoPrevKline.candlestick.color === 'green' &&
                  prevKline.candlestick.color === 'red' &&
                  kline.candlestick.color === 'red' &&
                  smallestLine > 0 &&
                  hasActiveLine(smallestLine, twoPrevKline.candlestick.close, twoPrevKline.candlestick.open);// &&
                  //prevKline.candlestick.close > prevKline.indicators.ema50 &&
                  //prevKline.candlestick.close > prevKline.indicators.ema21;

    let condition = false;
    let type = '';

    if(osdh1) {
        condition = true;
        type = 1;
        shortPoint = 100;
    }
    else {
        condition = false;
        type = "";
    }
    return { condition: condition, type: type, shortPoint};
}

const calculatePrevKlines = (prevKlines, analysisModel) => {
    let cuttedPrevKlines;
    if(analysisModel === "zombieSpot") {
        cuttedPrevKlines = prevKlines.splice(prevKlines.length - 30, 30);
    }
    else {
        cuttedPrevKlines = prevKlines.splice(prevKlines.length - 5, 5);
    }

    const firstPrevKline = [...cuttedPrevKlines][0];
    // Mum yeşilse açılış fiyatı, kırmızı ise kapanış fiyatı baz alınır.
    const firstPrevBasePrice = firstPrevKline.candlestick.color === 'green' ? firstPrevKline.candlestick.open : firstPrevKline.candlestick.close;
    // mumun orta fiyatını buluyor. alternatif olarak duracak.
    //const fivePrevBasePrice = fivePrevKline.candlestick.close > fivePrevKline.candlestick.open ? fivePrevKline.candlestick.close - ((fivePrevKline.candlestick.close - fivePrevKline.candlestick.open) / 2) : fivePrevKline.candlestick.open - ((fivePrevKline.candlestick.open - fivePrevKline.candlestick.close) / 2);

    let parallelEq = 0;
    let greenPrevKlines = 0;

    let closePrices = cuttedPrevKlines.map(p => p.candlestick.close);
    let openPrices = cuttedPrevKlines.map(p => p.candlestick.open);

    //let maxTopPrice = 0;
    //let maxBottomPrice = 100000;

    let maxTopPrice = Math.max(...[...closePrices, ...openPrices]);
    let maxBottomPrice = Math.min(...[...closePrices, ...openPrices]);

    const prevKlinesPercentage = cuttedPrevKlines.filter((k, i) => {
        let perc = k.candlestick.change;
        let percentage = 0;
        if(k.candlestick.color === 'green') {
            greenPrevKlines++;

            //maxTopPrice = k.candlestick.close > maxTopPrice ? k.candlestick.close : maxTopPrice;
            //maxBottomPrice = k.candlestick.open < maxBottomPrice ? k.candlestick.open : maxBottomPrice;

            // önceki mumların orta fiyatlarını bulup ona göre oranlıyor.
            /*
            const middlePrice = k.candlestick.close - ((k.candlestick.close - k.candlestick.open) / 2);
            percentage = Number(((fivePrevBasePrice - middlePrice) * 100 / middlePrice).toFixed(2));
            */
            percentage = Number(((firstPrevBasePrice - k.candlestick.close) * 100 / k.candlestick.close).toFixed(2));
        }
        else {
            //maxTopPrice = k.candlestick.open > maxTopPrice ? k.candlestick.open : maxTopPrice;
            //maxBottomPrice = k.candlestick.close < maxBottomPrice ? k.candlestick.close : maxBottomPrice;
            // önceki mumların orta fiyatlarını bulup ona göre oranlıyor.
            /*
            const middlePrice = k.candlestick.open - ((k.candlestick.open - k.candlestick.close) / 2);
            percentage = Number(((fivePrevBasePrice - middlePrice) * 100 / middlePrice).toFixed(2));
            */
            percentage = Number(((firstPrevBasePrice - k.candlestick.open) * 100 / k.candlestick.open).toFixed(2));
        }

        if(analysisModel === "zombieSpot") {
            if(i !== 0 && (percentage < 11 && percentage > -1)) {
                parallelEq++;
            }
        }
        else {
            if(i !== 0 && (percentage < 1 && percentage > -1)) {
                parallelEq++;
            }
        }

        if(analysisModel === "explosionLoose" || analysisModel === "zombieSpot") {
            return perc < 1 && perc > -1;
        }
        else if(analysisModel === "explosionTight") {
            return perc < 0.7 && perc > -0.7 && (k.candlestick.color === 'green' ? k.candlestick.close > k.indicators.ema50 : k.candlestick.open > k.indicators.ema50); 
        }

        return perc < 1 && perc > -1 && (k.candlestick.color === 'green' ? k.candlestick.close > k.indicators.ema50 : k.candlestick.open > k.indicators.ema50); 
        //return perc < 1 && perc > -1 && k.candlestick.close > k.indicators.ema21;
    });

    const prevKlinesAverageWidth = Number(((maxTopPrice - maxBottomPrice) * 100 / maxBottomPrice).toFixed(2));
    //console.log('maxTopPrice', maxTopPrice);
    //console.log('maxBottomPrice', maxBottomPrice);

    return {parallelEq: parallelEq, greenKlinesCount: greenPrevKlines, klinesPercentage: prevKlinesPercentage, klinesAverageWidth: prevKlinesAverageWidth, maxTopPrice: maxTopPrice}
} 

const prevKlinesPercentages = (prevKlines) => {
    let prevKlinesPerc = prevKlines.filter((k, i) => {
        let change = k.candlestick.change;
        return change < 1.00 && change > -1.00;
    });

    return prevKlinesPerc.length;
}

const prevObvComparison = (prevKlines) => {
    let result = true;
    prevKlines.map(k => {
        if(k.indicators.obv > k.indicators.obvMa21) {
            result = false;
        }
    });

    return result;
}

const calculateLongFindBottom = (kline, prevKline, currentDeal) => {
    return null;
}

const calculateEma20Test = (kline) => {
    const openLongT20 = kline.indicators.ema20 > kline.indicators.ema100 &&
    kline.candlestick.low <= kline.indicators.ema20 &&
    kline.candlestick.close > kline.indicators.ema20 &&
    kline.candlestick.color === 'green';
    return openLongT20;
}

const calculateRsiUpThirthy = (prevKline, lastKline) => {
    const upRsiThirthy = prevKline.indicators.rsi < 30 && lastKline.indicators.rsi >= 30;
    return upRsiThirthy;
}

const calculateRsiDownThirthy = (kline) => {
    const downRsiThirthy = kline.indicators.rsi < 30;
    return downRsiThirthy;
}

const calculateEma200 = (prevKline, kline) => {
    let isTrue = false;
    let type = '';

    // son mum yeşil ise;
    if(kline.candlestick.color === 'green') {
        // son mum yeşil ise;

        if(prevKline.candlestick.color === 'red' && prevKline.candlestick.close < prevKline.indicators.ema200 && prevKline.candlestick.open > prevKline.indicators.ema200 && kline.candlestick.close > kline.indicators.ema200 && kline.candlestick.open < kline.indicators.ema200) {
            // önceki mum kırmızı, önceki kapanış ema200 altında, önceki açılış ema200 üstünde, son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "DownUp";
            //console.log('Yeşil', 'Kırmızı', 'Son Ema200', kline.indicators.ema200, 'Mum kapanışı', kline.candlestick.close);
        }
        else if (kline.candlestick.close > kline.indicators.ema200 && kline.candlestick.open < kline.indicators.ema200) {
            // son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "Up";
            //console.log('Yeşil', 'Son Ema200', kline.indicators.ema200, 'Mum kapanışı', kline.candlestick.close);
        }
    }
    else {
        // son mum kırmızı ise;
        if(prevKline.candlestick.color === 'green' && prevKline.candlestick.close > prevKline.indicators.ema200 && prevKline.candlestick.open < prevKline.indicators.ema200 && kline.candlestick.close < kline.indicators.ema200 && kline.candlestick.open > kline.indicators.ema200) {
            // önceki mum yeşil, önceki kapanış em200 üstünde, önceki açılış ema200 altında, son mum kapanış ema200 altında, son mum açılış ema200 üstünde;          
            isTrue = true;
            type = "UpDown";
            //console.log('Kırmızı', 'Yeşil', 'Son Ema200', kline.indicators.ema200, 'Mum açılışı', kline.candlestick.open);
        }
        else if(kline.candlestick.close < kline.indicators.ema200 && kline.candlestick.open > kline.indicators.ema200) {
            isTrue = true;
            type = "Down";
            //console.log('Kırmızı', 'Son Ema200', kline.indicators.ema200, 'Mum açılışı', kline.candlestick.open);
            // LIT : ema200 yanlış hesaplanıyor. 5 Agu, 14:30 ile başlayan mum. aslı 1.002,487 hesaplanan 1.014
        }

    }
    return {condition: isTrue, type: type};
}

const calculateEma100 = (prevKline, kline) => {
    let isTrue = false;
    let type = '';

    // son mum yeşil ise;
    if(kline.candlestick.color === 'green') {
        // son mum yeşil ise;

        if(prevKline.candlestick.color === 'red' && prevKline.candlestick.close < prevKline.indicators.ema100 && prevKline.candlestick.open > prevKline.indicators.ema100 && kline.candlestick.close > kline.indicators.ema100 && kline.candlestick.open < kline.indicators.ema100) {
            // önceki mum kırmızı, önceki kapanış ema200 altında, önceki açılış ema200 üstünde, son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "DownUp";
        }
        else if (kline.candlestick.close > kline.indicators.ema100 && kline.candlestick.open < kline.indicators.ema100) {
            // son mum kapanış ema200 üstünde, son mum açılış ema200 altında;
            isTrue = true;
            type = "Up";
        }
    }
    else {
        // son mum kırmızı ise;
        if(prevKline.candlestick.color === 'green' && prevKline.candlestick.close > prevKline.indicators.ema100 && prevKline.candlestick.open < prevKline.indicators.ema100 && kline.candlestick.close < kline.indicators.ema100 && kline.candlestick.open > kline.indicators.ema100) {
            // önceki mum yeşil, önceki kapanış em200 üstünde, önceki açılış ema200 altında, son mum kapanış ema200 altında, son mum açılış ema200 üstünde;
            
            isTrue = true;
            type = "UpDown";
    
        }
        else if(kline.candlestick.close < kline.indicators.ema100 && kline.candlestick.open > kline.indicators.ema100) {
            isTrue = true;
            type = "Down";
        }

    }
    return {condition: isTrue, type: type};
}

// TODO: bu alan şu an supports state'i içinden ayrı olarak çekiliyor. Mum verisi içine eklenen supres objesinden alınabilir.
const calculateSupRes = (prevKline, kline, supports) => {
    let activeLine = supports.find(l => l.price >= kline.candlestick.low && l.price <= kline.candlestick.high);
    let sr = {
        condition: false,
        image: null,
        line: null,
        strength: null,
        prevLine: null,
        nextLine: null
    }
    if(activeLine) {
        let activeLineIndex = supports.map(x => x.price).indexOf(activeLine.price);
        sr.condition = true;
        sr.line = Number(activeLine.price.toFixed(5));
        sr.strength = activeLine.strength;
        sr.prevLine = activeLineIndex > 0 ? Number(supports[activeLineIndex - 1].price.toFixed(4)) : 0;
        sr.nextLine = activeLineIndex < supports.length - 1 ? Number(supports[activeLineIndex + 1].price.toFixed(4)) : 0;
        let prevKlineIsOnLine = hasActiveLine(activeLine.price, prevKline.candlestick.high, prevKline.candlestick.low);
        let prevIsGreen = prevKline.candlestick.color === 'green';

        if(kline.candlestick.color === 'green') {
            if(hasActiveLine(sr.line, kline.candlestick.close, kline.candlestick.open, 'no')) {
                if(prevKlineIsOnLine && !prevIsGreen) {
                    sr.image = 'redgreenzero';
                }
                else {
                    sr.image = 'greenzero';
                }   
            }
            else if(sr.line === kline.candlestick.open) {
                if(prevKlineIsOnLine && !prevIsGreen) {
                    sr.image = 'redgreenminus1';
                }
                else {
                    sr.image = 'greenminus1';
                }
            }
            else if(hasActiveLine(sr.line, kline.candlestick.open, kline.candlestick.low, 'no')) {
                if(prevKlineIsOnLine && !prevIsGreen) {
                    sr.image = 'redgreenminus2';
                }
                else {
                    sr.image = 'greenminus2';
                }
            }
            else if(sr.line === kline.candlestick.low) {
                if(prevKlineIsOnLine && !prevIsGreen) {
                    sr.image = 'redgreenminus3';
                }
                else {
                    sr.image = 'greenminus3';
                }
            }
            else if(sr.line === kline.candlestick.close) {
                sr.image = 'greenplus1';
            }
            else if(hasActiveLine(sr.line, kline.candlestick.high, kline.candlestick.close, 'no')) {
                sr.image = 'greenplus2';
            }
            else if(sr.line === kline.candlestick.high) {
                sr.image = 'greenplus3';
            }
            else {
                sr.image = null;
            }
        }
        else {
            // kline is red
            if(hasActiveLine(sr.line, kline.candlestick.open, kline.candlestick.close, 'no')) {
                if(prevKlineIsOnLine && prevIsGreen) {
                    sr.image = 'greenredzero';
                }
                else {
                    sr.image = 'redzero';
                }   
            }
            else if(sr.line === kline.candlestick.open) {
                if(prevKlineIsOnLine && prevIsGreen) {
                    sr.image = 'greenredplus1';
                }
                else {
                    sr.image = 'redplus1';
                }
            }
            else if(hasActiveLine(sr.line, kline.candlestick.high, kline.candlestick.open, 'no')) {
                if(prevKlineIsOnLine && prevIsGreen) {
                    sr.image = 'greenredplus2';
                }
                else {
                    sr.image = 'redplus2';
                }
            }
            else if(sr.line === kline.candlestick.high) {
                if(prevKlineIsOnLine && prevIsGreen) {
                    sr.image = 'greenredplus3';
                }
                else {
                    sr.image = 'redplus3';
                }
            }
            else if(sr.line === kline.candlestick.close) {
                sr.image = 'redminus1';
            }
            else if(hasActiveLine(sr.line, kline.candlestick.close, kline.candlestick.low, 'no')) {
                sr.image = 'redminus2';
            }
            else if(sr.line === kline.candlestick.low) {
                sr.image = 'redminus3';
            }
            else {
                sr.image = null;
            }
        }
    }
    return sr;
}

const calculateUpwardExpansion = (prevKlines, kline) => {
    let isTrue = false;
    let closePrices = prevKlines.map(p => p.candlestick.close);

    let highestPrice = Math.max(...[...closePrices]);
    let lowestPrice = Math.min(...[...closePrices]);
    let channelWidth = (highestPrice - lowestPrice) * 100 / lowestPrice;

    if(kline.candlestick.close > highestPrice) {
        let newChannelWidth = (kline.candlestick.close - lowestPrice) * 100 / lowestPrice;
        if(channelWidth < 4 && newChannelWidth > 4) {
            isTrue = true;
        }
    }

    return isTrue;
}

const calculateTrendBreaks = (prevKline, kline) => {
    let isOverflow = false;
    let prevCandle = prevKline.candlestick;
    let prevDown = prevKline.trends.down;
    let prevUp = prevKline.trends.up;
    let candle = kline.candlestick;
    let down = kline.trends.down;
    let up = kline.trends.up;

    if(candle.close > up.top &&
       candle.color === 'green' &&
       prevCandle.close < prevUp.top) {
        isOverflow = true;
    } 
    else if (candle.close > down.top &&
             candle.color === 'green' &&
             prevCandle.close < prevDown.top) {
                isOverflow = true;
    }
    return isOverflow;
}

const extendPercentageCalculate = (price, process, percent) => {
    let result = 0.0
    if(process == "plus")
        result = price + (price / 100) * percent
    else
        result = price - (price / 100) * percent
    return result;
}

const calculatePercentage = (kline) => {
    //console.log('calculatePercentage inside', kline);
    return Number(((kline.candlestick.close - kline.candlestick.open) * 100 / kline.candlestick.open).toFixed(2));
}

const calculateBetweenPercentage = (price1, price2) => {
    //console.log('calculatePercentage inside', kline);
    return Number(((price1 - price2) * 100 / price2).toFixed(2));
}

const hasActiveLine = (line, bigValue, smallValue, type) => {
    //return lines.filter(s => s.price <= bigValue && s.price >= smallValue); 
    if(type === "no") {
        // no equality
        return line < bigValue && line > smallValue;
    }
    else if (type === "small") {
        // just small value equality
        return line < bigValue && line >= smallValue;
    }
    else if (type === "big") {
        // just big value equality
        return line <= bigValue && line > smallValue;
    }
    else {
        // full equality
        return line <= bigValue && line >= smallValue;
    }
}

const isOnActiveLine = (activeLine, bigValue, smallValue) => {
    let isOnActiveLine = bigValue >= activeLine && smallValue <= activeLine;
    return isOnActiveLine;
}
