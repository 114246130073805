import FirebaseSymbols from '../data/firebase-symbols.json';
import FirebaseSymbolsTest from '../data/firebase-symbols-test.json';

const reducer = (state, action) => {
    //console.log(action);
    //console.log('reducer', FirebaseSymbolsTest.symbols.filter(s => s.market === "Binance"));
    let firebaseSpotSymbols = FirebaseSymbols.symbols.filter(s => s.market === "Binance" && s.state !== "inactive" && (s.type === "SPOT" || s.type === "BOTH"));
    let firebaseFuturesSymbols = FirebaseSymbols.symbols.filter(s => s.market === "Binance" && s.state !== "inactive" && (s.type === "FUTURES" || s.type === "BOTH"));
    switch (action.type) {
        case "MARKET_TYPE":
            return {
                ...state,
                marketType: action.payload,
                symbols: action.payload === 'FUTURES' ? firebaseFuturesSymbols : firebaseSpotSymbols
            }
        case "SAVE_FIREBASE_SYMBOLS":
            return {
                ...state,
                firebaseSymbols: action.payload,
            }
        case "SAVE_KLINES_LIST":
            //const oldPos = action.payload.positions.filter(p => p.closeType !== "NC");
            //const openPos = action.payload.positions.filter(p => p.closeType === "NC");
            console.log('save kline list', action.payload.activeDeals);
            return {
                ...state,
                kLinesList: action.payload.candlesticks,
                supports: action.payload.supports,
                oldPositions: action.payload.pastPositions,
                openPositions: action.payload.openPositions,
                currentDeals: action.payload.activeDeals,
                startListenSocket: true
            }
        case "CHANGE_KLINES_LIST":
            const changeOldPos = action.payload.positions.filter(p => p.closeType !== "NC");
            const changeOpenPos = action.payload.positions.filter(p => p.closeType === "NC");
            return {
                ...state,
                kLinesList: action.payload.candlesticks,
                oldPositions: changeOldPos,
                openPositions: changeOpenPos,
            }
        case "UPDATE_KLINES_LIST":
            /*const updatedKlines = {...state.kLinesList};
            Object.keys(updatedKlines).map(kline => {
                const updatdSymbolArray = [...updatedKlines[kline]];
                updatdSymbolArray.push(action.payload.candlesticks[kline]);
                updatedKlines[kline] = updatdSymbolArray;
                return true;
            });*/
            const closedPositions = action.payload.positions.filter(p => p.closeType !== "NC");
            const openPositions = action.payload.positions.filter(p => p.closeType === "NC");
            return {
                ...state,
                //kLinesList: {...updatedKlines},
                kLinesList: action.payload.candlesticks,
                supports: action.payload.supports,
                oldPositions: [...state.oldPositions, ...closedPositions],
                openPositions: openPositions,
                currentDeals: action.payload.activeDeals
            }
        case "FORCE_UPDATE_KLINES_LIST":
            // symbol: currentKlineValues.s, candlesticks: symbolKlinesList, position: openPosition
            const forceUpdatedKlines = {...state.kLinesList};
            forceUpdatedKlines[action.payload.symbol] = action.payload.candlesticks;

            const forceUpdatedPosition = [...state.allPositions];
            const posIndex = forceUpdatedPosition.findIndex(p => p.closeType === 'NC' && p.symbol === action.payload.symbol);

            forceUpdatedPosition.splice(posIndex, 1, action.payload.position);
            return {
                ...state,
                kLinesList: {...forceUpdatedKlines},
                allPositions: [...forceUpdatedPosition],
            }
        case "SAVE_SUPDEM_DATA":
            return {
                ...state,
                supdemData: action.payload,
            }
        case "UPDATE_CURRENT_DEALS":
            return {
                ...state,
                //currentDeals: action.payload.currentDeals,
                currentEma20Deals: action.payload.currentEma20Deals,
                currentRsiUp30Deals: action.payload.currentRsiUp30Deals,
                currentRsiDown30Deals: action.payload.currentRsiDown30Deals,
                currentEma100Deals: action.payload.currentEma100Deals,
                currentEma200Deals: action.payload.currentEma200Deals,
                currentSrDeals: action.payload.currentSrDeals,
                currentUpwardExpansions: action.payload.currentUpwardExpansions,
                currentTrendBreaks: action.payload.currentTrendBreaks
            }
        case "SHOW_CALCULATOR": 
            return {
                ...state,
                showCalculatorModal: action.payload
            }
        case "SHOW_ANALYSIS_MODAL": 
            return {
                ...state,
                showAnalysisModal: action.payload
            }
        case "CHANGE_ANALYSIS_MODEL": 
            return {
                ...state,
                analysisModel: action.payload
            }
        case "CHECK_SIMULATOR": 
            return {
                ...state,
                enabledSimulator: action.payload
            }
        case "SET_ANALYSIS_OPTIONS":
            return {
                ...state,
                analysisOptions: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
